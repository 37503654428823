import React, { Fragment } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import styles from './index.module.scss';
import HeadContainer from '../head-container';
import global from '../index.module.scss';
import WarningFooter from '../warning-footer/WarningFooter';
import { getImageFromS3 } from '../../imageService';

const infoList = [
  {
    title: '1994',
    subtitle: 'PMI вийшла на український ринок',
    text: 'Philip Morris International вийшла на український ринок у 1994 році та представлена ПрАТ «Філіп Морріс Україна» і ТОВ «Філіп Морріс Сейлз енд Дистриб’юшн». Головний український офіс Філіп Морріс знаходиться за адресою м. Київ, вул. Спаська, 30.'
  },
  {
    title: '$698 млн',
    subtitle: 'Загальний обсяг інвестицій',
    text: 'Пріоритетними напрямками інвестування компанії стали бездимні продукти для нагрівання тютюну та інноваційний виробничий центр біля Харкова.'
  },
  {
    title: '25,3 млд грн',
    subtitle: 'Податків сплачено до державного та місцевих за 2022 рік',
    text: 'За період роботи в Україні ПрАТ «Філіп Морріс Україна» і ТОВ «Філіп Морріс Сейлз енд Дистриб’юшн» сплатили близько 13,7 млрд доларів податків до державного та місцевих бюджетів. З них у 2022 році — 25,3 млрд грн.'
  },
  {
    title: 'Інноваційний',
    subtitle: 'виробничий центр біля Харкова',
    text:
      'Компанія має сучасну фабрику в селищі Докучаєвське Харківського району, Харківської області. Вона виготовляла низку міжнародних брендів як для українського, так і для закордонних ринків.\n\n' +
      'На виробництві було задіяно близько 900 осіб. Ще понад 300 людей через субпідрядні організації обслуговують інфраструктуру.'
  },
  {
    title: 'Соціальна',
    subtitle: 'відповідальність',
    text:
      'З початку повномасштабного вторгнення в Україну у 2022 році компанія профінансувала закупівлю 25 машин швидкої допомоги, які вже знаходяться у Києві, Сумах, Харкові та Миколаєві. Philip Morris International перерахувала 10 млн доларів США на підтримку міжнародних гуманітарних зусиль.\n\n' +
      'Компанія також стала донором проекту RE:Ukraine Housing, що передбачає будівництво тимчасового модульного житла поблизу Бучі. Йдеться про дві житлові секції на 15 сімей. Надалі в планах масштабування проєкту.\n\n' +
      'У грудні Філіп Морріс разом з агенцією DTF встановили 5 соціальних просторів «Сфери світла» у Києві, Одесі та Львові. Вони були оснащені генераторами та «старлінками», там можна було зігрітися, підзарядити девайси, а також попрацювати та випити гарячого чаю. «Сфери світла» могли одночасно прийняти близько 100 людей кожна.'
  }
];

const InfoScreen = ({ setScreenNumber, setReviewScreenNumber }) => {
  return (
    <div
      className={styles.infoContainer}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/light-blue-background.png'
        )}")`
      }}
    >
      <div className={styles.content}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <span className={styles.title}>Філіп Морріс Україна</span>
        {infoList.map((item, index) => (
          <Disclosure key={index} id={index.toString()}>
            {({ open }) => (
              <>
                <Disclosure.Button className={styles.panelButton}>
                  <div>
                    <p>{item.title}</p>
                    <p>{item.subtitle}</p>
                  </div>
                  <img
                    src="/images/lm-double-splash/blue-plus.svg"
                    alt="open-tab-icon"
                  />
                </Disclosure.Button>
                <Transition
                  as={Fragment}
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className={styles.panelContent}>
                    {item.text}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
        <div style={{ margin: '20px 0 15px 0' }}>
          <button
            className={global.menuButton}
            onClick={() => setScreenNumber(1)}
          >
            Повернутись до меню
          </button>
          <button
            className={global.reviewButton}
            style={{ marginTop: 14 }}
            onClick={setReviewScreenNumber}
          >
            Залишити відгук
          </button>
        </div>
      </div>
      <WarningFooter />
    </div>
  );
};

export default InfoScreen;
