import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageSelectChallenge = ({ challengeList, onSubmit, style = null }) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState({});
  const [challengeResult, setChallengeResult] = useState([]);

  useEffect(() => {
    if (!challengeList || !challengeList?.length) return
    setSelectQuestion(challengeList[0])
  }, [challengeList])

  const handleSelect = (answer) => {
    const isExistAnswer = challengeResult.find(
      (item) => item.questionId === selectQuestion.id
    );
    if (isExistAnswer) {
      const updatedResultList = challengeResult.filter(
        (item) => item.questionId !== selectQuestion.id
      );
      setChallengeResult([
        ...updatedResultList,
        { questionId: selectQuestion.id, answerId: answer.id }
      ]);
    } else {
      setChallengeResult([
        ...challengeResult,
        { questionId: selectQuestion.id, answerId: answer.id }
      ]);
    }
  };

  return (
    <div className={classNames(styles.imageSelectContainer, style && styles[style])}>
      <div className={styles.stepToolbar}>
        {challengeList.map((item, index) => (
          <div
            key={index}
            className={classNames(
              styles.stepItem,
              index === challengeStep && styles.activeStepItem
            )}
          />
        ))}
      </div>
      {selectQuestion && (
        <div className={styles.questionItem}>
          <span className={styles.titleQuestion}>{selectQuestion.title}</span>
          <div className={styles.imageList}>
            {selectQuestion.imageList?.map((item) => {
              const answer = challengeResult?.find(
                (item) => item.questionId === selectQuestion.id
              );

              return (
                <div
                  id={item.id}
                  key={item.id}
                  onClick={() => handleSelect(item)}
                  className={classNames(
                    styles.imageContainer,
                    answer?.answerId === item.id &&
                      styles.selectedImageContainer
                  )}
                >
                  <LazyLoadImage
                    src={item.url}
                    width={150}
                    height={150}
                    alt={item.id}
                    wrapperClassName={styles.imageItem}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        {challengeStep !== 0 && (
          <button
            className={global.reviewButton}
            onClick={() => {
              setSelectQuestion(challengeList[challengeStep - 1]);
              setChallengeStep(challengeStep - 1);
            }}
          >
            Назад
          </button>
        )}
        <button
          className={global.menuButton}
          onClick={() => {
            if (challengeList.length > challengeStep + 1) {
              setSelectQuestion(challengeList[challengeStep + 1]);
              setChallengeStep(challengeStep + 1);
            } else {
              onSubmit(challengeResult);
            }
          }}
          disabled={
            !challengeResult.find(
              (item) => item.questionId === selectQuestion.id
            )
          }
        >
          Далі
        </button>
      </div>
    </div>
  );
};

export default ImageSelectChallenge;
