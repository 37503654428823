import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../../imageService';

const FirstScreen = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        Нові медичні попереждення на пачках сигарет
      </p>
      <p className={styles.content}>
        11 січня 2024 року набули чинності зміни до статті 12 Закону України
        <a
          className={styles.link}
          href="https://zakon.rada.gov.ua/laws/show/2899-15#Text"
        >
          «Про заходи щодо попередження та зменшення вживання тютюнових виробів
          і їх шкідливого впливу на здоров’я населення».
        </a>
      </p>
      <p className={styles.content}>
        Вони стосуються медичних попереджень споживачів тютюнових виробів для
        куріння та вимог до упакування таких виробів.
      </p>
      <img
        src={getImageFromS3('images/ghws/first.png')}
        alt="first"
        className={styles.image}
      />
      <p className={styles.content}>
        Тепер на кожній пачці сигарет зазначаються 2 попередження - загальне та
        комбіноване, що супроводжується відповідною кольоровою ілюстрацією, а
        також розміщується інформаційне повідомлення. Площу медичних
        попереджень, які розміщуються на задній і передній сторонах пачки та
        містять картинку, збільшено з 50% до 65%.
      </p>
    </div>
  );
};

export default FirstScreen;
