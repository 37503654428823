import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const ForesightResultScreen = ({ handleNextScreen }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${getImageFromS3('images/hstss/bg.png')}")`
      }}
    >
      <div>
        <p className={styles.title}>Надихнувся передбаченням?</p>
        <p className={styles.description}>
          Цього тижня воно точно справдиться!
        </p>
      </div>
      <button className={styles.menuButton} onClick={handleNextScreen}>
        Далі
      </button>
    </div>
  );
};

export default ForesightResultScreen;
