import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    title: 'Хто є автором цитати?',
    description:
      '“Елегантність полягає не в тому, щоб вас помітили, а в тому, щоб вас запам\'ятали"',
    answerList: [
      { id: 1.1, title: 'А) Джорджо Армані', isCorrect: true },
      { id: 1.2, title: 'Б) Карл Лагерфельд', isCorrect: false },
      { id: 1.3, title: 'В) Ральф Лорен', isCorrect: false }
    ]
  },
  {
    id: 2,
    title: 'Кому з політичних діячів належить фраза?',
    description:
      '“Успіх — це вміння рухатись від невдачі до невдачі, не втрачаючи ентузіазму”',
    answerList: [
      { id: 2.1, title: 'А) Шарль де Голль', isCorrect: false },
      { id: 2.2, title: 'Б) Вінстон Черчилль', isCorrect: true },
      { id: 2.3, title: 'В) Михайло Грушевський', isCorrect: false }
    ]
  },
  {
    id: 3,
    title: 'Хто з модельєрів сказав ці слова?',
    description:
      '“Елегантність — це не те, що ви носите, а те, як ви носите це"',
    answerList: [
      { id: 3.1, title: 'А) Валентино Джаравані', isCorrect: false },
      { id: 3.2, title: 'Б) Карл Лагерфельд', isCorrect: true },
      { id: 3.3, title: 'В) Кельвін Кляйн', isCorrect: false }
    ]
  },
  {
    id: 4,
    title: 'Кому зі спортсменів належать ці мотивуючі слова?',
    description:
      '“Впевненість — це якість, яка відрізняє чемпіонів від звичайних гравців"',
    answerList: [
      { id: 4.1, title: 'А) Майкл Фелпс', isCorrect: false },
      { id: 4.2, title: 'Б) Кіліан Мбаппе', isCorrect: false },
      { id: 4.3, title: 'В) Вінс Ломбарді', isCorrect: true }
    ]
  },
  {
    id: 5,
    title: "Яка відома дизайнерка висловилась про взаємозв'язок стилю і моди?",
    description: '“Мода приходить і йде, а стиль залишається завжди”',
    answerList: [
      { id: 5.1, title: 'А) Рей Кавакубо', isCorrect: false },
      { id: 5.2, title: 'Б) Стелла Маккартні', isCorrect: false },
      { id: 5.3, title: 'В) Коко Шанель', isCorrect: true }
    ]
  }
];

const resultList = [
  {
    id: 1,
    title: 'Вітаємо! Тобі вдалось правильно поєднати фрази та їхніх авторів.',
    description:
      'Інакше й бути не могло, адже досконалість —- твоя невід’ємна риса.'
  },
  {
    id: 2,
    title: 'Ти і чужу мудрість знаєш, і свою маєш.',
    description:
      'Ти відкритий до мудрості інших, але кінцеве рішення завжди ухвалюєш сам. \n\n' +
      'Так тримати!'
  },
  {
    id: 3,
    title: 'Ти завжди орієнтуєшся на себе й не любиш поради інших.',
    description: 'Ця риса допомагає тобі ніколи не звертати зі свого шляху.'
  }
];

const AuthorOfQuoteChallenge = ({
  setScreenNumber,
  backgroundImage,
  afterResultButtonScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [correctAnswerValue, setCorrectAnswerValue] = useState({});
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [challengeStep]: value }));
      const isCorrect = selectQuestion.answerList.find(
        (answer) => answer.id === value.answerId
      )?.isCorrect;
      setIsAnswerCorrect(isCorrect);
    },
    [answers, challengeStep, selectQuestion]
  );

  const onFinishClick = () => {
    const answerCounts = {};
    for (const key in answers) {
      const answerNumber = parseInt(
        answers[key].answerId.toString().split('.')[1]
      );
      answerCounts[answerNumber] = (answerCounts[answerNumber] || 0) + 1;
    }

    let mostFrequentAnswer = null;
    let maxCount = 0;
    for (const answer in answerCounts) {
      if (answerCounts[answer] > maxCount) {
        maxCount = answerCounts[answer];
        mostFrequentAnswer = parseInt(answer);
      }
    }

    setCorrectAnswerValue(
      resultList.find((item) => item?.id === mostFrequentAnswer)
    );
    setIsResultScreen(true);
  };

  const handleAfterResult = () => {
    afterResultButtonScreen();
    setIsResultScreen(false);
    setIsWelcomeScreen(true);
    setChallengeStep(0);
    setCorrectAnswerValue({});
    setAnswers([]);
    setSelectQuestion(challengeList[0]);
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultScreen && (
        <div className={styles.welcomeContainer}>
          <p className={styles.title}>Хто автор цитати?</p>
          <p className={styles.description}>
            У тебе точно є власні правила стилю та елегантності. Пропонуємо
            поповнити скарбничку цитатами успішних людей.
            <br />
            <br /> Однак не все так просто — спробуй відгадати авторів
            висловлювань.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>
            Приймаю челендж!
          </button>
        </div>
      )}
      {!isWelcomeScreen && !isResultScreen && (
        <div className={styles.challengeContainer}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div className={styles.questionContainer}>
            <p className={styles.title}>{selectQuestion?.title}</p>
            <p>{selectQuestion.description}</p>
            <div className={styles.answerList}>
              {selectQuestion?.answerList?.map((item, index) => (
                <div
                  key={index.toString()}
                  className={classNames(
                    styles.answerItem,
                    item?.id === answers[challengeStep]?.answerId &&
                      styles.selectedAnswerItem,
                    answers[challengeStep]?.answerId === item?.id && {
                      [styles.correctAnswer]: isAnswerCorrect === true,
                      [styles.wrongAnswer]: isAnswerCorrect === false
                    }
                  )}
                  onClick={() =>
                    onAnswerClick({ answerId: item?.id, answer: item?.title })
                  }
                  style={{
                    backgroundImage: `url("${getImageFromS3(
                      'images/symbol-your-success-challenge/answer-background.png'
                    )}")`
                  }}
                >
                  <p>{item.title}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => {
                if (challengeStep > 0) {
                  setChallengeStep(challengeStep - 1);
                  setSelectQuestion(challengeList[challengeStep - 1]);
                } else {
                  setIsWelcomeScreen(true);
                  setSelectQuestion(challengeList[0]);
                  setChallengeStep(0);
                  setIsResultScreen(false);
                  setAnswers([]);
                }
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            {answers[challengeStep]?.answerId && (
              <div
                className={styles.rightButton}
                onClick={() => {
                  if (challengeStep + 1 >= challengeList?.length) {
                    onFinishClick();
                    return;
                  }
                  setIsAnswerCorrect(null);
                  setChallengeStep(challengeStep + 1);
                  setSelectQuestion(challengeList[challengeStep + 1]);
                }}
              >
                <span>Далі</span>
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {isResultScreen && !!correctAnswerValue && (
        <div className={styles.resultContainer}>
          <p className={styles.title}>{correctAnswerValue?.title}</p>
          <p className={styles.description}>
            {correctAnswerValue?.description}
          </p>
          <div className={styles.buttonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => {
                setChallengeStep(challengeList?.length - 1);
                setIsResultScreen(false);
                setCorrectAnswerValue({});
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            <div className={styles.rightButton} onClick={handleAfterResult}>
              <span>Далі</span>
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorOfQuoteChallenge;
