import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../../imageService';

const FourthScreen = () => {
  return (
    <div className={styles.container}>
      <img src={getImageFromS3('images/ghws/fourth.png')} alt="first" />
    </div>
  );
};

export default FourthScreen;
