import React, { useMemo, useState, useCallback } from 'react';
import HeadContainer from '../../head-container';
import styles from './index.module.scss';
import classNames from 'classnames';
import ProgressAnswerStep from './ProgressAnswerStep';
import ProgressQuestionStep from './ProgressQuestionStep';

export default function Progress({
  list,
  setScreenNumber,
  backgroundImage,
  setStep,
  step,
  setStatus
}) {
  const [isAnswered, setIsAnswered] = useState(false);

  const currentStep = useMemo(() => {
    return list[step];
  }, [list, step]);

  const answer = useCallback(() => {
    setIsAnswered(true);
  }, []);

  const nextStep = useCallback(() => {
    if (step === list.length - 1) {
      setStatus('finished');
      return;
    }
    setStep((prev) => prev + 1);
    setIsAnswered(false);
  }, [setStatus, setIsAnswered, setStep, step, list]);

  return (
    <div
      className={styles.ProgressContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {list.map((_, index) => (
          <div
            key={index}
            className={classNames(
              styles.stepItem,
              index === step && styles.activeStepItem
            )}
          />
        ))}
      </div>
      {isAnswered ? (
        <ProgressAnswerStep nextStep={nextStep} data={currentStep.answer} />
      ) : (
        <ProgressQuestionStep answer={answer} data={currentStep.question} />
      )}
    </div>
  );
}
