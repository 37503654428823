import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Confetti from 'react-confetti';
import Dot from './Dot';

export default function ProgressQuestionStep({
  data,
  nextStep,
  setStep,
  step
}) {
  const [differencesFound, setDifferencesFound] = useState(0);
  const [isSuccessAnimation, setIsSuccessAnimation] = useState(null);
  const [direction, setDirection] = useState('forward');
  let successAnimationTimeout = null;

  useEffect(() => {
    return () => {
      if (successAnimationTimeout) clearTimeout(successAnimationTimeout);
    };
  }, []);

  useEffect(() => {
    if (differencesFound >= 3 && direction === 'forward') {
      setIsSuccessAnimation(true);
      successAnimationTimeout = setTimeout(() => {
        setIsSuccessAnimation(false);
      }, 5000);
    }
  }, [differencesFound]);

  const goForward = () => {
    if (differencesFound >= 3) {
      setDifferencesFound(0);
      nextStep();
      setDirection('forward');
    }
  };

  const goBack = () => {
    setStep((prev) => prev - 1);
    setDifferencesFound(3);
    setDirection('backward');
  };

  return (
    <div>
      <div className={styles.bodyContainer}>
        {isSuccessAnimation && <Confetti tweenDuration={1500} />}
        <div className={styles.title}>
          Відмінностей знайдено: {differencesFound} / 3
        </div>
        <div className={styles.image}>
          <LazyLoadImage
            src={data.imageTop}
            width={315}
            height={165}
            alt={'image_1'}
          />
        </div>
        <div className={styles.image}>
          <LazyLoadImage
            src={data.imageBottom}
            width={315}
            height={165}
            alt={'image_2'}
          />
          {data.points.map((item, index) => (
            <Dot
              key={index}
              item={item}
              setDifferencesFound={setDifferencesFound}
              step={step}
              direction={direction}
            />
          ))}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button className={global.reviewButton} onClick={goBack}>
          Назад
        </button>
        <button className={global.menuButton} onClick={goForward}>
          Далі
        </button>
      </div>
    </div>
  );
}
