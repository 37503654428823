import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    title: 'Прикраса з якого матеріалу найдорожча?',
    imageList: [
      {
        title: 'Річкові перли',
        image: getImageFromS3('images/luxury-expert-challenge/1.1.png'),
        isCorrect: false
      },
      {
        title: 'Рожеве золото',
        image: getImageFromS3('images/luxury-expert-challenge/1.2.png'),
        isCorrect: true
      },
      {
        title: 'Срібло',
        image: getImageFromS3('images/luxury-expert-challenge/1.3.png'),
        isCorrect: false
      }
    ]
  },
  {
    id: 2,
    title: 'Сукня з якої тканини буде найбільш ексклюзивною?',
    imageList: [
      {
        title: 'Шовк',
        image: getImageFromS3('images/luxury-expert-challenge/2.1.png'),
        isCorrect: true
      },
      {
        title: 'Бавовна',
        image: getImageFromS3('images/luxury-expert-challenge/2.2.png'),
        isCorrect: false
      },
      {
        title: 'Сатин',
        image: getImageFromS3('images/luxury-expert-challenge/2.3.png'),
        isCorrect: false
      }
    ]
  },
  {
    id: 3,
    title: 'Який камінь зробить каблучку винятково дорогою?',
    imageList: [
      {
        title: 'Смарагд',
        image: getImageFromS3('images/luxury-expert-challenge/3.1.png'),
        isCorrect: false
      },
      {
        title: 'Рубін',
        image: getImageFromS3('images/luxury-expert-challenge/3.2.png'),
        isCorrect: true
      },
      {
        title: 'Місячний камінь',
        image: getImageFromS3('images/luxury-expert-challenge/3.3.png'),
        isCorrect: false
      }
    ]
  },
  {
    id: 4,
    title: 'Який матеріал є найбільш витонченим в оздобленні інтер’єру?',
    imageList: [
      {
        title: 'Граніт',
        image: getImageFromS3('images/luxury-expert-challenge/4.1.png'),
        isCorrect: false
      },
      {
        title: 'Дерево',
        image: getImageFromS3('images/luxury-expert-challenge/4.2.png'),
        isCorrect: false
      },
      {
        title: 'Мармур',
        image: getImageFromS3('images/luxury-expert-challenge/4.3.png'),
        isCorrect: true
      }
    ]
  }
];

const result = [
  {
    title: '',
    description:
      'Для тебе неважлива вартість речей. \n Ти керуєшся лише власною думкою та вподобаннями. Це робить тебе унікальним.'
  },
  {
    title: '',
    description:
      'Ти знаєшся на ексклюзивних речах, але здебільшого орієнтуєшся на власний смак. \n Це дозволяє тобі не втрачати індивідуальність.'
  },
  {
    title: 'Так тримати!',
    description:
      'Ти не лише розбираєшся в якості, а й знаєшся на вартості більшості речей.'
  },
  {
    title: 'Це неймовірно!',
    description:
      'Ти знаєш ціну всіх розкішних речей. Такий витончений смак у тебе з народження?'
  }
];

const LuxuryExpertChallenge = ({
  setScreenNumber,
  backgroundImage,
  afterResultButtonScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [correctAnswerValue, setCorrectAnswerValue] = useState(0);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultScreen && (
        <div className={styles.welcomeContainer}>
          <p className={styles.title}>Експерт з розкоші</p>
          <p className={styles.description}>
            Інколи однакові на перший погляд речі мають абсолютно різну ціну.{' '}
            <br />
            <br /> Як знавець всього ексклюзивного, спробуй здогадатись, який з
            запропонованих варіантів — найдорожчий.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>
            Приймаю челендж!
          </button>
        </div>
      )}
      {!isWelcomeScreen && !isResultScreen && (
        <div className={styles.challengeContainer}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div className={styles.questionContainer}>
            <p className={styles.title}>{selectQuestion?.title}</p>
            <div className={styles.imageList}>
              {selectQuestion &&
                selectQuestion?.imageList?.map((item) => (
                  <div
                    className={styles.imageItem}
                    onClick={() => {
                      if (challengeStep + 1 >= challengeList?.length) {
                        setIsResultScreen(true);
                        setCorrectAnswerValue(
                          item?.isCorrect
                            ? correctAnswerValue + 1
                            : correctAnswerValue
                        );
                        setSelectQuestion(challengeList[0]);
                        setChallengeStep(0);
                      } else {
                        setChallengeStep(challengeStep + 1);
                        setCorrectAnswerValue(
                          item?.isCorrect
                            ? correctAnswerValue + 1
                            : correctAnswerValue
                        );
                        setSelectQuestion(challengeList[challengeStep + 1]);
                      }
                    }}
                  >
                    <img src={item?.image} alt={item?.image} />
                  </div>
                ))}
            </div>
          </div>
          <div
            className={styles.buttonContainer}
            onClick={() => {
              setChallengeStep(0);
              setSelectQuestion(challengeList[0]);
              setCorrectAnswerValue(0);
              setIsWelcomeScreen(true);
              setIsResultScreen(false);
            }}
          >
            <img src="/images/luxury-expert-challenge/arrow.svg" alt="arrow" />
            <span>На початок</span>
          </div>
        </div>
      )}
      {isResultScreen && !isWelcomeScreen && (
        <div className={styles.resultContainer}>
          <p className={styles.title}>
            Твій результат {correctAnswerValue} з {challengeList?.length}
          </p>
          <p className={styles.description}>
            <p>
              {
                result[correctAnswerValue <= 0 ? 1 : correctAnswerValue - 1]
                  ?.title
              }
            </p>
            <p>
              {
                result[correctAnswerValue <= 0 ? 1 : correctAnswerValue - 1]
                  ?.description
              }
            </p>
          </p>
          <div className={styles.buttonContainer}>
            <div
              className={styles.buttonItem}
              onClick={() => {
                setChallengeStep(0);
                setSelectQuestion(challengeList[0]);
                setCorrectAnswerValue(0);
                setIsWelcomeScreen(true);
                setIsResultScreen(false);
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            <div
              className={styles.buttonItem}
              onClick={() => {
                setChallengeStep(0);
                setSelectQuestion(challengeList[0]);
                setCorrectAnswerValue(0);
                setIsWelcomeScreen(true);
                setIsResultScreen(false);
                afterResultButtonScreen();
              }}
            >
              <span>Далі</span>
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LuxuryExpertChallenge;
