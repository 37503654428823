import React, {useMemo} from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { useState } from 'react';
import { useCallback } from 'react';

const ANSWER_DELAYS_MS = 1000

export default function Progress({
  list,
  setScreenNumber,
  backgroundImage,
  setStep,
  step,
  setStatus,
}) {
  const [answers, setAnswers] = useState({});
  const [showQuizResult, setShowQuizResult] = useState(false);

  const currentStepAnswer = useMemo(() => answers[step], [answers, step]);

  const currentStep = useMemo(() => {
    return list[step];
  }, [list, step]);

  const onPrevNextStepClick = useCallback(
    (v) => {
      if (!currentStepAnswer?.value && v > 0) return

      const _newStep = step + v;

      if (_newStep < 0) {
        setStatus('start')
        return
      }

      setAnswers((prev) => ({ ...prev, [step]: { value: currentStepAnswer?.value, isCorrect: currentStep.correctAnswerKey === currentStepAnswer?.value } }));

      const changeStepTimeout = setTimeout(() => {
        if (_newStep > list.length - 1) {
          setStatus('finished');
          setShowQuizResult(true);
        } else {
          setStep(_newStep)
        }
      },v > 0 ? ANSWER_DELAYS_MS : 0)

      return () => {
        clearTimeout(changeStepTimeout)
      }
    },
    [answers, step, setStep, setStatus, list.length, showQuizResult]
  );

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [step]: { value, isCorrect: null } }));
    },
    [step]
  );
  return (
    <div
      className={styles.ProgressContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {list.map((_, index) => (
          <div
            key={index}
            className={classNames(
              styles.stepItem,
              index === step && styles.activeStepItem
            )}
          />
        ))}
      </div>
      <Questions
        title={currentStep.question}
        list={currentStep.answers}
        currentStepAnswer={currentStepAnswer}
        onAnswerClick={onAnswerClick}
        onPrevNextStepClick={onPrevNextStepClick}
        correctKey={currentStep.correctAnswerKey}
      />
    </div>
  );
}

function Questions({
  title,
  list,
  currentStepAnswer,
  onAnswerClick,
  onPrevNextStepClick,
}) {
  const isSelected = (key) => key === currentStepAnswer?.value

  const getClassName = (key) => {
    if (!isSelected(key)) return;
    let _className = styles.isSelected;
    const isCorrect = currentStepAnswer?.isCorrect
    if (typeof isCorrect !== 'boolean') return _className
    if (isCorrect) {
      _className = `${_className} ${styles.isCorrect}`
    } else {
      _className = `${_className} ${styles.isFailed}`
    }
    return _className
  }

  return (
    <>
      <div className={styles.bodyContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.optionsList}>
          {list.map((it, index) => {
            const char = {
              0: 'a',
              1: 'b',
              2: 'c',
              3: 'd'
            };
            return (
              <div
                key={it.key}
                className={classNames([styles.optionItem, getClassName(it.key)])}
                onClick={() => onAnswerClick(it.key)}
              >
                <div className={styles.optionItemNumber}>{char[index]}</div>
                <div className={styles.optionItemCopy}>{it.value}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={global.reviewButton}
          onClick={() => onPrevNextStepClick(-1)}
        >
          Назад
        </button>

        <button
          className={global.menuButton}
          onClick={() => onPrevNextStepClick(1)}
        >
          Далі
        </button>
      </div>
    </>
  );
}
