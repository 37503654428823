import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../head-container';
import WarningFooter from '../../components/lm-d-s/warning-footer/WarningFooter';
import { getImageFromS3 } from '../../imageService';

const PreMenuScreen = ({
  setScreenNumber,
  title,
  packImage,
  tobaccoText = 'Оновлена тютюнова суміш',
  characteristicButtonTitle = 'Характеристики',
  menuButtonTitle = 'Головне меню',
  handleCharacteristicButton,
  handleMenuButton,
  backgroundImage
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.characteristicContainer}
        style={{ backgroundImage: backgroundImage }}
      >
        <div className={styles.content}>
          <HeadContainer setScreenNumber={setScreenNumber} />
          <span className={styles.title}>{title}</span>
          <div className={styles.packContainer}>
            <img
              src={packImage}
              className={styles.packImage}
              alt="pack-image"
            />
            <div className={styles.compositionContainer}>
              <img
                src={getImageFromS3('images/lm-double-splash/tobacco.png')}
                alt="tobacco-icon"
              />
              <p>{tobaccoText}</p>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.menuButton}
              onClick={handleCharacteristicButton}
            >
              {characteristicButtonTitle}
            </button>
            <button
              className={styles.reviewButton}
              style={{ marginTop: 10 }}
              onClick={handleMenuButton}
            >
              {menuButtonTitle}
            </button>
          </div>
        </div>
        <WarningFooter screenNumber={0} />
      </div>
    </div>
  );
};

export default PreMenuScreen;
