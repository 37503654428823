import React from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useContext } from 'react';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';

const ResultInfoScreen = ({
  setScreenNumber,
  setNextScreenNumber,
  setViewScreenNumber,
  title,
  subtitle,
  description,
  image,
  isBigImage = false,
  backgroundImage,
  style = null,
  nextButtonText = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setNextScreenNumber();
    sendEvent('exit_challenge');
  }, [sendEvent, setNextScreenNumber]);
  return (
    <div
      className={classNames(
        styles.resultContainer,
        style ? styles[style] : null
      )}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div>
        {image && (
          <img
            className={classNames(
              styles.resultImage,
              isBigImage && styles.bigResultImage
            )}
            src={image}
            alt="result-image"
          />
        )}
        <p className={styles.resultTitle}>{title}</p>
        {subtitle && <p className={styles.resultSubtitle}>{subtitle}</p>}
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.resultDescription}>{description}</p>
        {setViewScreenNumber && (
          <button className={global.menuButton} onClick={setViewScreenNumber}>
            Дивитися
          </button>
        )}
        <button
          className={classNames(
            setViewScreenNumber ? global.reviewButton : global.menuButton,
            styles.button
          )}
          onClick={onButtonClickHandler}
        >
          {nextButtonText ? nextButtonText : 'Далі'}
        </button>
      </div>
    </div>
  );
};

export default ResultInfoScreen;
