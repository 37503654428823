import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const ResultScreen = ({ handleNextScreen, qrCodeSrc }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${getImageFromS3('/images/hstss/bg.png')}")`
      }}
    >
      <div>
        <p className={styles.title}>Дякую,</p>
        <p className={styles.description}>
          що обираєш продукцію Філіп Морріс Україна.
        </p>
        <img className={styles.qrImage} src={qrCodeSrc} alt="qr" />
        <p className={styles.subDescription}>
          Долучайся до нас у телеграмі, щоб бути на звʼязку зі своїм брендом.
        </p>
      </div>
      <button className={styles.menuButton} onClick={handleNextScreen}>
        До початку
      </button>
    </div>
  );
};

export default ResultScreen;
