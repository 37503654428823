import React, { useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../components/lm-d-s/menu-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../common/characteristic-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import ProhibitedChapterScreen from '../components/lm-d-s/prohibited-chapter-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import WelcomeScreen from '../components/lm-d-s/welcome-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import QuizChallenge from '../common/challenge-component/quiz-challenge';
import MemoryChallenge from '../components/special-capsules/challenge/memory-select-challenge';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';

const filterCharacteristicList = [
  {
    description: (
      <span>
        Класичний ацетатний фільтр з високоякісної целюлози, який затримує
        тверді частинки сигаретного диму. Містить капсулу зі смаком зелених
        соковитих фруктів та капсулу зі смаком літнього нектару.
      </span>
    ),
    bordered: true
  }
];

const characteristicInfoList = [
  {
    title: 'Формат ',
    description: '- King Size Slim (Кінг сайз слім)'
  },
  {
    title: 'Кількість сигарет у пачці ',
    description: '– 20'
  },
  {
    title: 'Кількість пачок у блоці ',
    description: '– 10'
  }
];

const characteristicStyle = {
  height: '280%'
};

const menu = [
  {
    id: 'challenge',
    screenNumber: 14,
    name: 'Челендж січня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 15,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: -1,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 2,
    name: 'Сигарети з капсулами забороняють?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const LMDSTest = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
          />
        );
      }
      case 0: {
        return (
          <WelcomeScreen
            setScreenNumber={cb}
            handleBackButton={() => setScreenNumber(1)}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/violet-background.png'
            )}")`}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            title="L&M Loft Double Splash"
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={filterCharacteristicList}
            infoList={characteristicInfoList}
            tobaccoFirst="Вміст смол, мг - 6"
            tobaccoSecond="Вміст нікотину, мг - 0.5"
            packImage={getImageFromS3('images/lm-double-splash/pack.png')}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(12)}
            backgroundImage={getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}
            filterIcon={getImageFromS3(
              'images/lm-double-splash/filter-icon.png'
            )}
            nextButtonTitle="Залишити відгук"
            style={characteristicStyle}
          />
        );
      }
      case 5: {
        return (
          <QuizChallenge
            setScreenNumber={() => setScreenNumber(15)}
            type="who-are-you-in-the-company-of-friends"
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/menu-background.png'
            )}")`}
            resultTitleFontSize={48}
            hasListMarkers={false}
            hasResultPrev
            hasResultNav={false}
            style="sohoPlaylist"
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося"
            subtitle="тобі було цікаво"
            description="Дослухайся до наших порад і зроби зустріч із друзями незабутньою!"
            image={getImageFromS3(
              'images/lm-double-splash/challenge/september-challenge/finally-icon.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/violet-background.png'
            )}")`}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <MemoryChallenge
            setScreenNumber={cb}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-special/background.png'
            )}")`}
            handleNextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      case 15: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default LMDSTest;
