import React, { useCallback, useMemo } from 'react';
import HeadContainer from '../../head-container';
import styles from './index.module.scss';
import classNames from 'classnames';
import ProgressQuestionStep from './ProgressQuestionStep';

export default function Progress({
  list,
  setScreenNumber,
  backgroundImage,
  setStep,
  step,
  setStatus
}) {
  const currentStep = useMemo(() => {
    return list[step];
  }, [list, step]);

  const nextStep = useCallback(() => {
    if (step === list.length - 1) {
      setStatus('finished');
      return;
    }
    setStep((prev) => prev + 1);
  }, [setStatus, setStep, step, list]);

  return (
    <div
      className={styles.ProgressContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {list.map((_, index) => (
          <div
            key={index}
            className={classNames(
              styles.stepItem,
              index === step && styles.activeStepItem
            )}
          />
        ))}
      </div>
      <ProgressQuestionStep
        data={currentStep}
        setStep={setStep}
        step={step}
        nextStep={nextStep}
      />
    </div>
  );
}
