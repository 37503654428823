import { getImageFromS3 } from '../../imageService';

export const challengeList = [
  {
    id: '1',
    title: 'Обирай страву до смаку',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/challenge/1.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/challenge/1.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/challenge/1.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/challenge/1.4.png')
      }
    ]
  },
  {
    id: '2',
    title: 'Який лук для тебе найстильніший?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/challenge/2.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/challenge/2.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/challenge/2.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/challenge/2.4.png')
      }
    ]
  },
  {
    id: '3',
    title: 'Який інтер’єр найбільше подобається?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/challenge/3.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/challenge/3.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/challenge/3.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/challenge/3.4.png')
      }
    ]
  },
  {
    id: '4',
    title: 'Як мрієш провести вихідні?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/challenge/4.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/challenge/4.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/challenge/4.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/challenge/4.4.png')
      }
    ]
  },
  {
    id: '5',
    title: 'Обирай десерт',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/challenge/5.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/challenge/5.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/challenge/5.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/challenge/5.4.png')
      }
    ]
  }
];

export const newChallengeList = [
  {
    id: '1',
    title: 'Обери найкращий спосіб підняти настрій',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/new-challenge/1.1.jpg')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/new-challenge/1.2.jpg')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/new-challenge/1.3.jpg')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/new-challenge/1.4.jpg')
      }
    ]
  },
  {
    id: '2',
    title: 'Який будинок для тебе найгарніший?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/new-challenge/2.1.jpg')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/new-challenge/2.2.jpg')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/new-challenge/2.3.jpg')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/new-challenge/2.4.jpg')
      }
    ]
  },
  {
    id: '3',
    title: 'Яке хобі ти обрав би для себе?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/new-challenge/3.1.jpg')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/new-challenge/3.2.jpg')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/new-challenge/3.3.jpg')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/new-challenge/3.4.jpg')
      }
    ]
  },
  {
    id: '4',
    title: 'Обирай місце для прогулянки',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/new-challenge/4.1.jpg')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/new-challenge/4.2.jpg')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/new-challenge/4.3.jpg')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/new-challenge/4.4.jpg')
      }
    ]
  },
  {
    id: '5',
    title: 'На чому поїдеш сьогодні?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/lm-double-splash/new-challenge/5.1.jpg')
      },
      {
        id: '2',
        url: getImageFromS3('images/lm-double-splash/new-challenge/5.2.jpg')
      },
      {
        id: '3',
        url: getImageFromS3('images/lm-double-splash/new-challenge/5.3.jpg')
      },
      {
        id: '4',
        url: getImageFromS3('images/lm-double-splash/new-challenge/5.4.jpg')
      }
    ]
  }
];

export const resultList = [
  {
    id: '1',
    image: getImageFromS3('images/lm-double-splash/challenge/result-1.png'),
    title: 'Прихильник традицій',
    description:
      'Ти вважаєш, що тренди — це щось тимчасове. Саме тому й цінуєш традиції за їхню вічну вишуканість.'
  },
  {
    id: '2',
    image: getImageFromS3('images/lm-double-splash/challenge/result-2.png'),
    title: 'Любитель екзотики',
    description:
      'Ти відкритий до світу і не втрачаєш жодної можливості спробувати щось нове.'
  },
  {
    id: '3',
    image: getImageFromS3('images/lm-double-splash/challenge/result-3.png'),
    title: 'Фанат поєднань',
    description:
      'Ти обожнюєш різноманіття і вдало поєднуєш класику з сучасними трендами.'
  },
  {
    id: '4',
    image: getImageFromS3('images/lm-double-splash/challenge/result-4.png'),
    title: 'Поціновувач класики',
    description:
      'Ти обираєш вічну класику, але завжди готовий спробувати щось нове.'
  }
];

export const newResultList = [
  {
    id: '1',
    image: getImageFromS3('images/lm-double-splash/challenge/result-1.png'),
    title: 'Прихильник традицій',
    description:
      'Для тебе тренди - це тимчасове явище. Тому й обожнюєш традиції за їхню вічну вишуканість.'
  },
  {
    id: '2',
    image: getImageFromS3('images/lm-double-splash/challenge/result-2.png'),
    title: 'Любитель екзотики',
    description:
      'Твоя цікавість до світу не має меж. Саме тому ти завжди відкритий до нових вражень.'
  },
  {
    id: '3',
    image: getImageFromS3('images/lm-double-splash/challenge/result-3.png'),
    title: 'Фанат поєднань',
    description:
      'Ти не уявляєш життя без різноманіття і вдало поєднуєш класику з сучасними трендами.'
  },
  {
    id: '4',
    image: getImageFromS3('images/lm-double-splash/challenge/result-4.png'),
    title: 'Поціновувач класики',
    description:
      'Твій вибір - це вічна класика. Але ти не втрачаєш нагоди спробувати щось нове.'
  }
];
