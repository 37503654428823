import React, { useContext, useEffect, useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import WarningFooter from '../common/warning-footer/WarningFooter';
import AuthorityScreen from '../common/authority-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../common/characteristic-screen';
import ChallengeScreen from '../components/lm-d-s/challenge-screen';
import QuizScreen from '../components/lm-d-s/quiz-screen';
import FinalQuizScreen from '../components/lm-d-s/final-quiz-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import WelcomeChallengeScreen from '../common/challenge-component/welcome-challenge-screen';
import AdviceChallenge from '../common/challenge-component/advice-challenge';
import { challengeList } from '../consts/what_does_it_take_to_lead_people';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import QuizChallenge from '../common/challenge-component/quiz-challenge';
import AdventureChallenge from '../components/adventure';
import WordGame from '../common/word-game';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';

const partners = {
  cig: 'https://cig-poshta.in.ua/shop/marlboro/?utm_source=SP',
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=marlboro/'
};

const PROJECT_NAME = 'Marlboro touch';

const filterCharacteristicList = [
  {
    description: (
      <span>
        <b>Firm filter</b> - щільний ацетатний фільтр, який краще тримає форму
        та менше розмокає
      </span>
    ),
    bordered: false
  }
];

const characteristicInfoList = [
  {
    title: 'Формат ',
    description: '- King Size Slim (Кінг сайз слім)'
  },
  {
    title: 'Кількість сигарет у пачці ',
    description: '– 20'
  },
  {
    title: 'Кількість пачок у блоці ',
    description: '– 10'
  }
];

const menu = [
  // {
  //   id: 'challenge',
  //   screenNumber: 14,
  //   name: 'Що треба, щоб вести за собою людей?',
  //   icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  // },
  // {
  //   id: 'challenge-2',
  //   screenNumber: 17,
  //   name: 'Який ти лідер?',
  //   icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  // },
  // {
  //   id: 'challenge-3',
  //   screenNumber: 18,
  //   name: 'ПРИГОТУЙСЯ ДО ІНТЕРАКТИВНОЇ ПРИГОДИ',
  //   icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  // },
  {
    id: 'challenge-4',
    screenNumber: 19,
    name: 'Челендж січня', //Як щодо ігр зі словами?
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 9,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: -1,
    name: 'Характеристики сигарет',
    icon: getImageFromS3(
      '/images/lm-double-splash/characteristic-menu-icon.svg'
    )
  },
  // {
  //   id: 'characteristic',
  //   screenNumber: 4,
  //   name: 'Характеристики сигарет',
  //   icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  // },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 2,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const MLBTTest = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const { init } = useContext(DataLayerEventContext);
  const [brandLine, setBrandLine] = useState('');

  useEffect(() => {
    init(PROJECT_NAME);
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(20);
            }}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/background.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            partners={partners}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return <InfoScreen setScreenNumber={cb} />;
      }
      case 4: {
        return (
          <CharacteristicScreen
            title="MARLBORO TOUCH"
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={filterCharacteristicList}
            infoList={characteristicInfoList}
            tobaccoFirst="Вміст смол, мг - 6"
            tobaccoSecond="Вміст нікотину, мг - 0.5"
            packImage={getImageFromS3('images/marlboro/mt/pack.png')}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            backgroundImage={getImageFromS3('images/marlboro/background.png')}
            filterIcon={getImageFromS3('images/pmn/filter.png')}
          />
        );
      }
      case 5: {
        return <ChallengeScreen setScreenNumber={cb} />;
      }
      case 6: {
        return <QuizScreen setScreenNumber={cb} />;
      }
      case 7: {
        return <QuizScreen setScreenNumber={cb} />;
      }
      case 8: {
        return <FinalQuizScreen setScreenNumber={cb} />;
      }
      case 9: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/background.png'
            )}")`}
            title={<>Що треба, щоб вести за собою людей?</>}
            description="Ми розібралися і підготували для тебе 5 рис, притаманних справжнім лідерам."
            buttonTitle="Цікаво? Гортай!"
            setNextScreenNumber={() => setScreenNumber(15)}
          />
        );
      }
      case 15: {
        return (
          <AdviceChallenge
            setScreenNumber={cb}
            challengeList={challengeList}
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/background.png'
            )}")`}
            setScreenNumberAfterResult={() => setScreenNumber(16)}
            style="mlb-lead-people"
          />
        );
      }
      case 16: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося, тобі було цікаво!"
            description="Ми в Marlboro віримо, що лідерські якості є у кожного і їх можна розвивати."
            image="/images/marlboro/what_does_it_take_to_lead_people/final.svg"
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/background.png'
            )}")`}
          />
        );
      }
      case 17: {
        return (
          <QuizChallenge
            setScreenNumber={setScreenNumber}
            type="which-are-you-a-leader"
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/background.png'
            )}")`}
            resultTitleFontSize={24}
          />
        );
      }
      case 18: {
        return (
          <AdventureChallenge
            setScreenNumber={cb}
            backgroundImage={`url("${getImageFromS3(
              'images/adventure/bg.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 19: {
        return (
          <WordGame
            type="marlboro"
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/background.png'
            )}")`}
          />
        );
      }
      case 20: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
      <WarningFooter screenNumber={screenNumber} />
    </div>
  );
};

export default MLBTTest;
