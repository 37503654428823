import React, { useMemo } from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { useState } from 'react';
import { useCallback } from 'react';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import { useContext } from 'react';

export default function Progress({
  list,
  resultList,
  setScreenNumber,
  backgroundImage,
  setStep,
  step,
  setStatus,
  resultTitleFontSize,
  hasListMarkers = true,
  style = null,
  hasResultPrev = false,
  hasResultNav = true
}) {
  const { sendEvent } = useContext(DataLayerEventContext);
  const [answers, setAnswers] = useState({});
  const [showQuizResult, setShowQuizResult] = useState(false);
  const isFirstStep = useMemo(() => step === 0, [step]);

  const currentStepAnswer = useMemo(() => answers[step], [answers, step]);

  const currentStep = useMemo(() => {
    return list[step];
  }, [list, step]);

  const onPrevNextStepClick = useCallback(
    (v) => {
      if (showQuizResult && v > 0) {
        setStatus('finished');
        return;
      }

      if (v > 0 && step === list.length - 1 && currentStepAnswer) {
        setShowQuizResult(true);
        return;
      }

      if (v < 0 && step === list.length - 1) {
        setShowQuizResult(false);
        return;
      }

      if (v > 0 && !answers[step]) return;
      setStep((prev) => prev + v);
    },
    [answers, step, setStep, setStatus, list.length, showQuizResult]
  );

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [step]: value }));
    },
    [step]
  );
  return (
    <div
      className={classNames(
        styles.ProgressContainer,
        style ? styles[style] : null
      )}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {(!showQuizResult || hasResultNav) && (
        <div className={styles.stepToolbar}>
          {list.map((_, index) => (
            <div
              key={index}
              className={classNames(
                styles.stepItem,
                index === step && styles.activeStepItem
              )}
            />
          ))}
        </div>
      )}
      {showQuizResult ? (
        <Result
          onPrevNextStepClick={onPrevNextStepClick}
          answers={answers}
          resultList={resultList}
          resultTitleFontSize={resultTitleFontSize}
          sendEvent={sendEvent}
          style={style}
          hasPrev={hasResultPrev}
        />
      ) : (
        <Questions
          isFirstStep={isFirstStep}
          title={currentStep.question}
          list={currentStep.answers}
          currentStepAnswer={currentStepAnswer}
          onAnswerClick={onAnswerClick}
          onPrevNextStepClick={onPrevNextStepClick}
          hasListMarkers={hasListMarkers}
          style={style}
        />
      )}
    </div>
  );
}

function Result({
  onPrevNextStepClick,
  answers,
  resultList,
  resultTitleFontSize,
  sendEvent,
  style = null,
  hasPrev = false
}) {
  const result = useMemo(() => {
    const items = Object.values(answers);
    const most = items
      .sort(
        (a, b) =>
          items.filter((v) => v === a).length -
          items.filter((v) => v === b).length
      )
      .pop();
    return resultList[most - 1];
  }, [answers, resultList]);

  const onClick = useCallback(() => {
    onPrevNextStepClick(1);
    sendEvent('end_challenge');
  }, [sendEvent, onPrevNextStepClick]);
  const onClickPrev = useCallback(() => {
    onPrevNextStepClick(-1);
  }, [sendEvent, onPrevNextStepClick]);
  return (
    <>
      <div
        className={classNames(
          styles.bodyContainer,
          styles.bodyContainerResult,
          style ? styles[style] : null
        )}
      >
        {result.prefix && (
          <span className={styles.prefix}>{result.prefix}</span>
        )}
        <div className={styles.bodyWr}>
          <div
            className={styles.title}
            style={{ fontSize: resultTitleFontSize ?? 30, fontWeight: 700 }}
          >
            {result.title}
          </div>
          <div className={styles.picture}>
            <img src={result.image} alt="#" />
          </div>
        </div>
        <div className={styles.description}>{result.description}</div>
      </div>
      <div className={styles.buttonContainer}>
        {hasPrev && (
          <button className={global.reviewButton} onClick={onClickPrev}>
            Назад
          </button>
        )}
        <button className={global.menuButton} onClick={onClick}>
          Далі
        </button>
      </div>
    </>
  );
}

function Questions({
  isFirstStep,
  title,
  list,
  currentStepAnswer,
  onAnswerClick,
  onPrevNextStepClick,
  hasListMarkers = true,
  style = null
}) {
  return (
    <>
      <div
        className={classNames(
          styles.bodyContainer,
          style ? styles[style] : null
        )}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.optionsList}>
          {list.map((it, index) => {
            const isSelected = it.key === currentStepAnswer;
            const char = {
              0: 'a',
              1: 'b',
              2: 'c',
              3: 'd'
            };
            return (
              <div
                key={it.key}
                className={classNames(
                  styles.optionItem,
                  isSelected ? styles.isSelected : null
                )}
                onClick={() => onAnswerClick(it.key)}
              >
                {hasListMarkers && (
                  <div className={styles.optionItemNumber}>{char[index]}</div>
                )}
                <div className={styles.optionItemCopy}>{it.value}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {!isFirstStep && (
          <button
            className={global.reviewButton}
            onClick={() => onPrevNextStepClick(-1)}
          >
            Назад
          </button>
        )}

        <button
          className={global.menuButton}
          onClick={() => onPrevNextStepClick(1)}
        >
          Далі
        </button>
      </div>
    </>
  );
}
