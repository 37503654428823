import React, { useContext, useEffect, useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import ProhibitedChapterScreen from '../components/lm-d-s/prohibited-chapter-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FourOptionsChallenge from '../common/challenge-component/four-options-challenge';
import AuthorityScreen from '../common/authority-screen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import WelcomeScreen from '../components/lm-l-m/welcome-screen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import QuizChallenge from '../common/challenge-component/quiz-challenge';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';

const menu = [
  {
    id: 'challenge',
    screenNumber: 14,
    name: 'Челендж січня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 6,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  /* {
    id: 'authority',
    screenNumber: 2,
    name: 'Сигарети з капсулами забороняють?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  }, */
  {
    id: 'difference',
    screenNumber: 15,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const novelPurple = {
  title: 'Philip Morris Novel Purple',
  pack: getImageFromS3('images/packs/philip-morris/novel-purple.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  isDarkButton: true,
  isNewMixture: true,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const lmLoftPurple = {
  title: 'L&M Loft Purple',
  pack: getImageFromS3('images/packs/lm/loft-purple.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  isDarkButton: true,
  isNewMixture: true,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const lmLoftGreen = {
  title: 'L&M Loft Green',
  pack: getImageFromS3('images/packs/lm/loft-green.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  isDarkButton: true,
  isNewMixture: true,
  newMixtureText: 'Особлива тютюнова суміш',
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const chesterfieldRetuned = {
  title: 'Chesterfield Retuned',
  pack: getImageFromS3('images/packs/chesterfield/retuned.png'),
  background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  isDarkButton: true,
  isNewMixture: true,
  newMixtureText: 'Особлива тютюнова суміш',
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const philipMorrisNovelMixSummer = {
  title: 'Philip Morris Novel Mix Summer',
  pack: getImageFromS3('images/packs/philip-morris/novel-mix-summer.png'),
  tobaccoFirst: 'Вміст смол, мг - 6',
  tobaccoSecond: 'Вміст нікотину, мг - 0.5',
  filterIcon: getImageFromS3('images/pmn/pmnms/filter.png'),
  background: `url("${getImageFromS3('images/pmn/background.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          <b>Класичний ацетатний фільтр</b>
          <br /> з високоякісної целюлози, який затримує тверді частинки
          сигаретного диму. Містить капсулу зі смаком літніх соковитих фруктів.
        </span>
      ),
      bordered: false
    }
  ]
};

const lmLoftMix = {
  title: 'L&M Loft Mix',
  pack: getImageFromS3('images/packs/lm/loft-mix.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.4',
  filterIcon: getImageFromS3('images/lm-loft-mix/filter.png'),
  background: `url("${getImageFromS3(
    'images/lm-double-splash/menu-background.png'
  )}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          <b>Класичний ацетатний фільтр</b>
          <br /> з високоякісної целюлози, який затримує тверді частинки
          сигаретного диму. Містить капсулу зі смаком лісових ягід.
        </span>
      ),
      bordered: false
    }
  ]
};

const bondPremiumMix = {
  title: 'Bond Street Premium Mix',
  pack: getImageFromS3('images/packs/bond/premium-mix.png'),
  tobaccoFirst: 'Вміст смол, мг - 5',
  tobaccoSecond: 'Вміст нікотину, мг - 0.4',
  filterIcon: getImageFromS3('images/bond/filter.png'),
  background: `url("${getImageFromS3('images/bond/background.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          <b>Класичний ацетатний фільтр</b> з високоякісної целюлози, який
          затримує тверді частинки сигаретного диму. Містить капсулу зі смаком
          лісових ягід.
        </span>
      ),
      bordered: false
    }
  ]
};

const LM4 = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('L&M Loft Mix');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 0: {
        return (
          <WelcomeScreen
            setScreenNumber={cb}
            handleBackButton={() => setScreenNumber(1)}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/violet-background.png'
            )}")`}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={lmLoftMix?.isNewMixture}
            title={lmLoftMix?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={lmLoftMix?.filterCharacteristicList}
            infoList={lmLoftMix?.characteristicInfoList}
            tobaccoFirst={lmLoftMix?.tobaccoFirst}
            tobaccoSecond={lmLoftMix?.tobaccoSecond}
            packImage={lmLoftMix?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={lmLoftMix?.background}
            filterIcon={lmLoftMix?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <FourOptionsChallenge
            setScreenNumber={setScreenNumber}
            type="default"
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
          />
        );
      }
      case 6: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося"
            subtitle="тобі було цікаво"
            description="Дослухайся до наших порад і зроби зустріч із друзями незабутньою!"
            image={getImageFromS3(
              'images/lm-double-splash/challenge/september-challenge/finally-icon.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <QuizChallenge
            setScreenNumber={() => setScreenNumber(10)}
            type="who-are-you-in-the-company-of-friends"
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
            resultTitleFontSize={48}
            style="lmStyles"
            hasResultPrev={false}
          />
        );
      }
      case 15: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      case 17: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={lmLoftGreen?.isNewMixture}
            title={lmLoftGreen?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={lmLoftGreen?.filterCharacteristicList}
            infoList={lmLoftGreen?.characteristicInfoList}
            tobaccoFirst={lmLoftGreen?.tobaccoFirst}
            tobaccoSecond={lmLoftGreen?.tobaccoSecond}
            packImage={lmLoftGreen?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={lmLoftGreen?.background}
            filterIcon={lmLoftGreen?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 18: {
        return (
          <CharacteristicScreen
            brandId="chesterfield"
            isNewMixture={chesterfieldRetuned?.isNewMixture}
            title={chesterfieldRetuned?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={
              chesterfieldRetuned?.filterCharacteristicList
            }
            infoList={chesterfieldRetuned?.characteristicInfoList}
            tobaccoFirst={chesterfieldRetuned?.tobaccoFirst}
            tobaccoSecond={chesterfieldRetuned?.tobaccoSecond}
            packImage={chesterfieldRetuned?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={chesterfieldRetuned?.background}
            filterIcon={chesterfieldRetuned?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 19: {
        return (
          <CharacteristicScreen
            brandId="philip-morris"
            isNewMixture={philipMorrisNovelMixSummer?.isNewMixture}
            title={philipMorrisNovelMixSummer?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={
              philipMorrisNovelMixSummer?.filterCharacteristicList
            }
            infoList={philipMorrisNovelMixSummer?.characteristicInfoList}
            tobaccoFirst={philipMorrisNovelMixSummer?.tobaccoFirst}
            tobaccoSecond={philipMorrisNovelMixSummer?.tobaccoSecond}
            packImage={philipMorrisNovelMixSummer?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={philipMorrisNovelMixSummer?.background}
            filterIcon={philipMorrisNovelMixSummer?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 20: {
        return (
          <CharacteristicScreen
            brandId="bond"
            isNewMixture={bondPremiumMix?.isNewMixture}
            title={bondPremiumMix?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={bondPremiumMix?.filterCharacteristicList}
            infoList={bondPremiumMix?.characteristicInfoList}
            tobaccoFirst={bondPremiumMix?.tobaccoFirst}
            tobaccoSecond={bondPremiumMix?.tobaccoSecond}
            packImage={bondPremiumMix?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={bondPremiumMix?.background}
            filterIcon={bondPremiumMix?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 21: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={lmLoftPurple?.isNewMixture}
            title={lmLoftPurple?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={lmLoftPurple?.filterCharacteristicList}
            infoList={lmLoftPurple?.characteristicInfoList}
            tobaccoFirst={lmLoftPurple?.tobaccoFirst}
            tobaccoSecond={lmLoftPurple?.tobaccoSecond}
            packImage={lmLoftPurple?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={lmLoftPurple?.background}
            filterIcon={lmLoftPurple?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 22: {
        return (
          <CharacteristicScreen
            brandId="philip-morris"
            isNewMixture={novelPurple?.isNewMixture}
            title={novelPurple?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={novelPurple?.filterCharacteristicList}
            infoList={novelPurple?.characteristicInfoList}
            tobaccoFirst={novelPurple?.tobaccoFirst}
            tobaccoSecond={novelPurple?.tobaccoSecond}
            packImage={novelPurple?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={novelPurple?.background}
            filterIcon={novelPurple?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default LM4;
