import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';
import CharacteristicPlusComponents from '../../../common/characteristic-plus-components';
import classNames from 'classnames';
import { Dialog, DialogContent } from '@mui/material';
import { getImageFromS3 } from '../../../imageService';

const PackScreen = ({
  setScreenNumber,
  onNextButtonClick,
  backgroundImage
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [plusNumber, setPlusNumber] = useState(0);

  return (
    <div className={styles.wrapper} style={{ backgroundImage }}>
      <div className={styles.container}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <p className={styles.title}>Chesterfield Original</p>
        <p className={styles.description}>
          Червоний – це про насиченість смаку
        </p>
        <div className={styles.imageContainer}>
          <img src={getImageFromS3('images/kv4/pack.png')} alt="pack" />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(1);
            }}
            className={classNames(styles.plusIcon, styles.firstPlus)}
          />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(2);
            }}
            className={classNames(styles.plusIcon, styles.secondPlus)}
          />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(3);
            }}
            className={classNames(styles.plusIcon, styles.thirdPlus)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.reviewButton} onClick={onNextButtonClick}>
            Далі
          </button>
        </div>
      </div>
      <div className={styles.footer}>
        <WarningFooter />
      </div>
      <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        sx={{
          backdropFilter: 'blur(10px)'
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: '10px',
            top: 10
          }
        }}
        fullWidth
        maxWidth={300}
      >
        <DialogContent
          sx={{
            background: 'rgba(9, 0, 65, 0.40)',
            position: 'relative'
          }}
        >
          <img
            className={styles.closeIcon}
            src={getImageFromS3('images/lm-special/close-dialog.png')}
            alt="closeIcon"
            onClick={() => setIsOpenDialog(false)}
          />
          <div className={styles.dialogContainer}>
            {plusNumber === 1 && (
              <div className={styles.tabaccoContainer}>
                <img
                  src={getImageFromS3('images/lm-double-splash/tobacco.png')}
                  alt="tabacco"
                />
                <p>Вміст смол, мг - 9</p>
                <p>Вміст нікотину, мг - 0.7</p>
              </div>
            )}
            {plusNumber === 1 && (
              <div className={styles.newMixtureContainer}>
                Оригінальний тютюн Chesterfield
              </div>
            )}
            {plusNumber === 2 && (
              <div className={styles.filterContainer}>
                <img
                  src={getImageFromS3('images/kv4/filter.png')}
                  alt="filterIcon"
                />
                <p>
                  Класичний ацетатний фільтр з високоякісної целюлози, який
                  затримує тверді частинки сигаретного диму.
                </p>
              </div>
            )}
            {plusNumber === 3 && (
              <div className={styles.formatContainer}>
                <img
                  src={getImageFromS3('images/lm-double-splash/pack-info.png')}
                  alt="first-plus"
                />
                <div className={styles.filterInfoBlock}>
                  <span>
                    <span>Формат - </span>
                    King Size ("Кінг сайз")
                  </span>
                  <span>
                    <span>Кількість сигарет у пачці – </span>
                    20
                  </span>
                  <span>
                    <span>Кількість пачок у блоці – </span>
                    10
                  </span>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PackScreen;
