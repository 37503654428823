import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import MenuScreen from '../common/menu-screen';
import ProhibitedChapterScreen from '../common/prohibited-chapter-screen';
import InfoScreen from '../components/lm-d-s/info-screen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import FourOptionsChallenge from '../common/challenge-component/four-options-challenge';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import SelectSeasonChallenge from '../common/challenge-component/select-season-challenge';
import AuthorityScreen from '../common/authority-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/lm-d-s/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import AllBrandsScreen from '../common/all-brands-screen';
import SymbolYourSuccessChallenge from '../common/challenge-component/symbol-your-success-challenge';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';

const menu = [
  {
    id: 'challenge',
    screenNumber: 8,
    name: 'Челендж січня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 6,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'difference',
    screenNumber: 15,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const selectedPack = {
  title: 'Parliament Silver Super Slims',
  pack: getImageFromS3('images/packs/parliament/silver-super-slims.png'),
  tobaccoFirst: 'Вміст смол, мг - 3',
  tobaccoSecond: 'Вміст нікотину, мг - 0.3',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3('images/prl-lpe/bg.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size (Кінг сайз)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  characteristicListTitle: (
    <div>
      <p>
        Оригінальний Recessed<sup>тм</sup> фільтр
      </p>
      <p>
        Фільтр складається з 3-x <br /> частин:
      </p>
    </div>
  ),
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const partners = {
  rozetka:
    'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce,bond,chesterfield,marlboro,parliament,philip-morris/',
  cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
};

const PRLSSS = ({ setAppNumber }) => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');
  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('PRL-S-SS');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 0: {
        return (
          <CommerceVideoScreen
            videoSrc={getImageFromS3('videos/prl-lpe.mp4')}
            setScreenNumber={setScreenNumber}
            nextScreenNumber={1}
            changeScreenImmediately
            controls
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/prl-lpe/bg.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(12)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="24UA-PRL-S-SS"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            characteristicListTitle={selectedPack?.characteristicListTitle}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <FourOptionsChallenge
            setScreenNumber={setScreenNumber}
            type="default"
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
          />
        );
      }
      case 6: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося"
            subtitle="тобі було цікаво"
            description="Дослухайся до наших порад і зроби зустріч із друзями незабутньою!"
            image={getImageFromS3(
              'images/lm-double-splash/challenge/september-challenge/finally-icon.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
          />
        );
      }
      case 8: {
        return (
          <SymbolYourSuccessChallenge
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("${getImageFromS3(
              'images/prl-lpe/bg.png'
            )}")`}
            afterResultButtonScreen={() => setScreenNumber(10)}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <SelectSeasonChallenge
            backgroundImage={`url("${getImageFromS3(
              'images/lm-double-splash/background-new.png'
            )}")`}
            setScreenNumber={setScreenNumber}
            type="harmony_in_self-knowledge-lm"
            style="soho"
            setNextScreenNumber={() => setScreenNumber(10)}
          />
          // <QuizChallenge
          //   setScreenNumber={() => setScreenNumber(10)}
          //   type="who-are-you-in-the-company-of-friends"
          //   backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
          //   resultTitleFontSize={48}
          //   style={'lmStyles'}
          //   hasResultPrev={false}
          // />
        );
      }
      case 15: {
        return (
          <AuthorityScreen
            partners={partners}
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="new-parliament-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default PRLSSS;
