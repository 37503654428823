import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const foresightFirstScreen = [
  'Ти зустрінеш давнього знайомого,...',
  'Ти отримаєш серйозну пропозицію,...',
  'Дуже скоро тобі подарують щось цінне,...',
  'Незабаром ти станеш на крок ближче до мрії,...',
  'Ти відкриєш у собі новий талант,...',
  'З тобою поділяться несподіваними новинами,...',
  'Скоро тобі випаде чудова можливість,...',
  'У тебе виникне блискуча ідея,...',
  'Хтось цікавий запросить тебе на каву,...',
  'Близька людина змусить тебе усміхнутися,...'
];

const foresightSecondScreen = [
  '...але це принесе деякі виклики.',
  '...від цього чекай добрячий прибуток.',
  '...але все змінить несподіваний поворот.',
  '...та все зайде у глухий кут.',
  '...це додасть тобі приємних клопотів.',
  '...від цього будеш купатися у щасті.',
  '...але не завищуй свої очікування.',
  '...це зробить життя трошки яскравішим.',
  '...це народить безліч різних думок.',
  '...зверни на це особливу увагу.'
];

const ForesightScreen = ({ setScreenNumber, handleNextScreen }) => {
  const [step, setStep] = useState(0);
  const [isPushedFirstCard, setIsPushedFirstCard] = useState(false);
  const [isPushedSecondCard, setIsPushedSecondCard] = useState(false);
  const [firstText, setFirstText] = useState('');
  const [firstTextIndex, setFirstTextIndex] = useState(0);
  const [secondText, setSecondText] = useState('');
  const [secondTextIndex, setSecondTextIndex] = useState(0);

  useEffect(() => {
    if (isPushedFirstCard && firstTextIndex !== 50) {
      setTimeout(() => {
        setFirstText(
          foresightFirstScreen
            .sort(() => Math.random() - 0.5)
            .find((item) => item !== firstText)
        );
        setFirstTextIndex(firstTextIndex + 1);
      }, 50);
    }
    setFirstText(
      foresightFirstScreen
        .sort(() => Math.random() - 0.5)
        .find((item) => item !== firstText)
    );
  }, [isPushedFirstCard, firstTextIndex]);

  useEffect(() => {
    if (isPushedSecondCard && secondTextIndex !== 50) {
      setTimeout(() => {
        setSecondText(
          foresightSecondScreen
            .sort(() => Math.random() - 0.5)
            .find((item) => item !== secondText)
        );
        setSecondTextIndex(secondTextIndex + 1);
      }, 50);
    }
    setSecondText(
      foresightSecondScreen
        .sort(() => Math.random() - 0.5)
        .find((item) => item !== secondText)
    );
  }, [isPushedSecondCard, secondTextIndex]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${getImageFromS3('images/hstss/bg.png')}")`
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '95%'
        }}
      >
        <div>
          {step === 0 && (
            <>
              <p className={styles.title}>Твоє передбачення:</p>
              <div className={styles.contentContainer}>
                <div
                  className={classNames(
                    styles.card,
                    isPushedFirstCard && styles.cardActive
                  )}
                >
                  <div
                    className={styles.cardFront}
                    style={{ backgroundColor: '#E7D2FF', color: '#4E188E' }}
                    onClick={() => setIsPushedFirstCard(true)}
                  >
                    тут...
                  </div>
                  <div
                    className={styles.cardBack}
                    style={{ backgroundColor: '#E7D2FF', color: '#4E188E' }}
                  >
                    {firstText}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.card,
                    isPushedSecondCard && styles.cardActive
                  )}
                >
                  <div
                    className={styles.cardFront}
                    style={{ backgroundColor: '#6405D2' }}
                    onClick={() => {
                      if (isPushedFirstCard) setIsPushedSecondCard(true);
                    }}
                  >
                    ...і тут
                  </div>
                  <div
                    className={styles.cardBack}
                    style={{ backgroundColor: '#6405D2' }}
                  >
                    {secondText}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {isPushedFirstCard && isPushedSecondCard && (
          <button className={styles.menuButton} onClick={handleNextScreen}>
            Далі
          </button>
        )}
      </div>
    </div>
  );
};

export default ForesightScreen;
