import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const WelcomeScreen = ({ setScreenNumber, handleNextScreen }) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${getImageFromS3('images/hstss/bg.png')}")`
      }}
    >
      <div className={styles.content}>
        <p className={styles.title}>Давай розпочнемо?</p>
        <button className={styles.menuButton} onClick={handleNextScreen}>
          Почати
        </button>
      </div>
      <div className={styles.footer}>
        <p>Натискаючи «ДалI» ти робиш запит на отримання</p>
        <p>інформації про продукцію компанії Філіп Морріс</p>
        <p>засобами дистанційного зв'язку відповідно до</p>
        <p>частини 2 статті 15 Закону «Про захист прав</p>
        <p>споживачів»</p>
      </div>
    </div>
  );
};

export default WelcomeScreen;
