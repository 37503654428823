import React, { useContext, useState, useEffect } from 'react';
import styles from './CommerceVideoScreen.module.css';
import VideoComponent from '../video-component/VideoComponent';
import { ScreensContext } from '../../contexts/screensContext';
import ChooseStepBtn from '../chooseStepBtn/ChooseStepBtn';
import { getImageFromS3 } from '../../imageService';

export default function CommerceVideoScreen({
  setScreenNumber,
  videoSrc,
  novel,
  dataLayerEvent,
  nextScreenNumber,
  changeScreenImmediately = false,
  skipScreenImmediately = false,
  controls = true,
  buttonStyle = 'default', // default, skip-video
  hasButtonArrow = false
}) {
  const { setEndSessionType, isDisplayedAll /*setIsDisplayedAll */ } =
    useContext(ScreensContext);
  const [firstTimeOnScreen, setFirstTimeOnScreen] = useState(true);
  const [videoIsViewed, setVideoIsViewed] = useState(false);

  useEffect(() => {
    if (isDisplayedAll) {
      setFirstTimeOnScreen(false);
    }
  }, [isDisplayedAll]);

  const nextBtnClickHandle = () => {
    if (novel) {
      if (window.dataLayer && firstTimeOnScreen) {
        window.dataLayer.push({
          event: 'Novel_next1'
        });
      }
    } else {
      if (window.ga && firstTimeOnScreen) {
        window.ga('send', 'event', {
          eventCategory: dataLayerEvent || 'EventPage5',
          eventAction: 'Click',
          eventLabel: 'Page5',
          eventValue: 5
        });
      }
      if (window.dataLayer && firstTimeOnScreen) {
        window.dataLayer.push({
          event: dataLayerEvent || 'EventPage5'
        });
      }
    }
    setEndSessionType('SESSION_END');
    setScreenNumber(nextScreenNumber !== undefined ? nextScreenNumber : 4);
  };

  const endVideoHandler = () => {
    setVideoIsViewed(true);
  };

  const nextStepMaybe = isDisplayedAll || videoIsViewed;

  if (changeScreenImmediately && nextStepMaybe) {
    nextBtnClickHandle();
  }

  return (
    <div className={styles.videoWr}>
      <VideoComponent
        sourcePath={videoSrc || getImageFromS3('videos/new_video.mp4')}
        onVideoEnd={() => endVideoHandler()}
        controls={controls}
      />
      {nextStepMaybe ||
        (skipScreenImmediately && (
          <ChooseStepBtn
            style={buttonStyle}
            execute={nextBtnClickHandle}
            hasTextArrow={hasButtonArrow}
          />
        ))}
    </div>
  );
}
