import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import { getImageFromS3 } from '../imageService';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/lm-d-s/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import WelcomeScreen from '../components/unsubscribe/welcome-screen';
import PhoneApproveScreen from '../components/unsubscribe/phone-approve-screen';
import AppreciationScreen from '../components/unsubscribe/appreciation-screen';
import SuccessScreen from '../components/unsubscribe/success-screen';

const Unsubscribe = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('Unsubscribe');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case 0: {
        return (
          <WelcomeScreen
            setScreenNumber={cb}
            approveButtonClick={() => setScreenNumber(1)}
            cancelButtonClick={() => setScreenNumber(2)}
            backgroundImage={`url("${getImageFromS3(
              'images/unsubscribe/unsubscribe-bg.png'
            )}")`}
          />
        );
      }
      case 1: {
        return (
          <PhoneApproveScreen
            setScreenNumber={cb}
            handleNextButtonClick={() => setScreenNumber(3)}
            backgroundImage={`url("${getImageFromS3(
              'images/unsubscribe/unsubscribe-bg.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <AppreciationScreen
            setScreenNumber={cb}
            backgroundImage={`url("${getImageFromS3(
              'images/unsubscribe/unsubscribe-bg.png'
            )}")`}
          />
        );
      }
      case 3: {
        return (
          <SuccessScreen
            setScreenNumber={cb}
            backgroundImage={`url("${getImageFromS3(
              'images/unsubscribe/unsubscribe-bg.png'
            )}")`}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="unsubscribe">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default Unsubscribe;
