import React, { useState } from 'react';
import ImageSelectChallenge from '../../../common/challenge-component/image-select-challenge';
import styles from './index.module.scss';
import global from '../index.module.scss';
import HeadContainer from '../../../common/head-container';
import { newChallengeList, newResultList } from '../const';
import { getImageFromS3 } from '../../../imageService';

const QuizScreen = ({ setScreenNumber }) => {
  const [isResultView, setIsResultView] = useState(false);
  const [resultData, setResultData] = useState(null);

  const uniqueElements = (arr) => {
    let result = [];

    for (let str of arr) {
      if (!result.includes(str)) {
        result.push(str);
      }
    }

    return result;
  };

  const setRandomResult = (min, max) => {
    const randomValue = Math.floor(Math.random() * 2);

    setResultData(newResultList[[min, max][randomValue] - 1]);
    setIsResultView(true);
  };

  const handleSubmitQuiz = (result) => {
    let resultValues = {};
    result.forEach((item) => {
      resultValues[item.answerId] = (resultValues[item.answerId] || 0) + 1;
    });
    if (resultValues[1] === 2 && resultValues[2] === 2) {
      setRandomResult(1, 2);
      return;
    }
    if (resultValues[1] === 2 && resultValues[3] === 2) {
      setRandomResult(1, 3);
      return;
    }
    if (resultValues[1] === 2 && resultValues[4] === 2) {
      setRandomResult(1, 4);
      return;
    }
    if (resultValues[2] === 2 && resultValues[3] === 2) {
      setRandomResult(2, 3);
      return;
    }
    if (resultValues[2] === 2 && resultValues[4] === 2) {
      setRandomResult(2, 4);
      return;
    }
    if (resultValues[3] === 2 && resultValues[4] === 2) {
      setRandomResult(3, 4);
      return;
    }

    for (let i = 1; i < 5; i++) {
      const isEqualValues = result.every(
        (elem) => elem.answerId === i.toString()
      );
      const isEqualButNotLastArray = result.filter(
        (item) => item.answerId === i.toString()
      );
      const isTwoEqual = isEqualButNotLastArray.length === 2;
      const uniquesValue = uniqueElements(result.map((item) => item.answerId));

      if (
        isEqualValues ||
        isEqualButNotLastArray.length >= 3 ||
        (isTwoEqual && uniquesValue.length - 1 === 3)
      ) {
        setResultData(newResultList[i - 1]);
        setIsResultView(true);
        return;
      }
    }
  };

  return (
    <div
      className={styles.quizContainer}
      style={{
        backgroundImage: getImageFromS3(
          'images/lm-double-splash/menu-background.png'
        )
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {!isResultView && (
        <ImageSelectChallenge
          challengeList={newChallengeList}
          onSubmit={(result) => handleSubmitQuiz(result)}
        />
      )}
      {isResultView && resultData && (
        <div className={styles.resultContainer}>
          <p className={styles.resultTitle}>Твій результат</p>
          <img
            src={resultData.image}
            alt={resultData.id}
            className={styles.resultImage}
          />
          <p
            className={styles.resultTitle}
            style={{
              fontSize: 20,
              lineHeight: '22px',
              maxWidth: 173,
              marginTop: 0
            }}
          >
            {resultData.title}
          </p>
          <p className={styles.resultDescription}>{resultData.description}</p>
          <button
            className={global.menuButton}
            style={{ marginTop: 10 }}
            onClick={() => setScreenNumber(8)}
          >
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default QuizScreen;
