import React, { useMemo } from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { useState } from 'react';
import { useCallback } from 'react';

export default function Progress({
  list,
  setScreenNumber,
  backgroundImage,
  setStep,
  step,
  setStatus
}) {
  const [answers, setAnswers] = useState({});

  const isFirstStep = useMemo(() => step === 0, [step]);
  const currentStepOptions = useMemo(
    () => list[step].question.options,
    [list, step]
  );
  const currentStepPicture = useMemo(
    () => list[step].question.image,
    [list, step]
  );
  const currentStepAnswer = useMemo(() => list[step].answer, [list, step]);

  const currentStepMyAnswer = useMemo(() => answers[step], [answers, step]);

  const isStepAnswered = useMemo(() => !!answers[step], [answers, step]);

  const onPrevNextStepClick = useCallback(
    (v) => {
      if (v && step === list.length - 1) {
        setStatus('finished');
        return;
      }
      if (v > 0 && !answers[step]) return;

      setStep((prev) => prev + v);
    },
    [answers, step, setStep, setStatus, list.length]
  );

  const onAnswerClick = useCallback(
    (value) => {
      if (answers[step]) return;
      setAnswers((prev) => ({ ...prev, [step]: value }));
    },
    [answers, step]
  );
  return (
    <div
      className={styles.ProgressContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {list?.map((_, index) => (
          <div
            key={index}
            className={classNames(
              styles.stepItem,
              index === step && styles.activeStepItem
            )}
          />
        ))}
      </div>
      <div className={styles.bodyContainer}>
        <Picture
          isStepAnswered={isStepAnswered}
          currentStepAnswer={currentStepAnswer}
          currentStepMyAnswer={currentStepMyAnswer}
          picture={currentStepPicture}
        />
        <div className={styles.optionsList}>
          {currentStepOptions?.map((it) => {
            return (
              <Option
                key={it.key}
                onAnswerClick={() => onAnswerClick(it.key)}
                it={it}
                isStepAnswered={isStepAnswered}
                currentStepAnswer={currentStepAnswer}
                currentStepMyAnswer={currentStepMyAnswer}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {!isFirstStep && (
          <button
            className={global.reviewButton}
            onClick={() => onPrevNextStepClick(-1)}
          >
            Назад
          </button>
        )}

        <button
          className={global.menuButton}
          onClick={() => onPrevNextStepClick(1)}
        >
          Далі
        </button>
      </div>
    </div>
  );
}

function Picture({
  isStepAnswered,
  currentStepAnswer,
  currentStepMyAnswer,
  picture
}) {
  const answerCopy = useMemo(() => {
    if (!isStepAnswered) return;
    if (currentStepAnswer.key === currentStepMyAnswer)
      return `Вірно! ${currentStepAnswer.description}`;
    return `Не правильно! ${currentStepAnswer.description}`;
  }, [
    isStepAnswered,
    currentStepMyAnswer,
    currentStepAnswer?.key,
    currentStepAnswer?.description
  ]);

  return (
    <div className={styles.picture}>
      <img src={picture} alt="" />
      {answerCopy && <div className={styles.answer}>{answerCopy}</div>}
    </div>
  );
}

function Option({
  onAnswerClick,
  it,
  isStepAnswered,
  currentStepAnswer,
  currentStepMyAnswer
}) {
  const isWrongAnswer = useMemo(() => {
    if (!isStepAnswered) return false;
    if (currentStepMyAnswer === currentStepAnswer.key) return false;
    if (currentStepMyAnswer !== it.key) return false;
    return true;
  }, [isStepAnswered, it.key, currentStepAnswer.key, currentStepMyAnswer]);

  const correctAnswer = useMemo(() => {
    if (!isStepAnswered) return false;
    if (currentStepAnswer.key !== it.key) return false;
    return true;
  }, [isStepAnswered, currentStepAnswer.key, it.key]);

  const style = useMemo(() => {
    if (isWrongAnswer)
      return {
        background: 'rgba(180, 15, 15, 0.30)'
      };
    if (correctAnswer)
      return {
        outline: '1px solid #FFF',
        background: 'rgba(15, 108, 180, 0.60)'
      };
    return undefined;
  }, [isWrongAnswer, correctAnswer]);

  return (
    <div className={styles.optionItem} style={style} onClick={onAnswerClick}>
      {it.value}
    </div>
  );
}
