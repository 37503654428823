import React, { useCallback, useContext } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../head-container';
import { getImageFromS3 } from '../../../../imageService';
import useTelegramLink from '../../../../hooks/useTelegramLink';
import { DataLayerEventContext } from '../../../../contexts/dataLayerEventContext';

const ResultComponent = ({ setScreenNumber, otpStatus }) => {
  const isSuccess = otpStatus === 'PENDING_OTP';
  const { telegramLink } = useTelegramLink();
  const { sendEvent } = useContext(DataLayerEventContext);

  const onTelegramClick = useCallback(() => {
    window.open(telegramLink, '_blank');
    sendEvent('telegram');
  }, [sendEvent, telegramLink]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/violet-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.content}>
        <p className={styles.title}>
          {isSuccess ? 'Дякуємо за відгук!' : 'Дякуємо!'}
        </p>
        <p className={styles.subtitle}>
          {isSuccess
            ? 'Твій мобільний рахунок буде поповнено найближчим часом.'
            : 'Для отримання поповнення приходь наступного місяця!'}
        </p>
        <p className={styles.description}>
          Долучайся у Телеграм, щоб бути на зв'язку зі своїм брендом.
        </p>
        <div className={styles.linkContainer}>
          <img
            src={getImageFromS3('images/lm-double-splash/telegram.png')}
            alt="telegram-icon"
            onClick={onTelegramClick}
          />
        </div>
        <button
          onClick={() => setScreenNumber(1)}
          className={styles.backButton}
        >
          Повернутися до меню
        </button>
      </div>
    </div>
  );
};

export default ResultComponent;
