import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    title: 'У якому місті найбільше мішленівських зірок?',
    answerList: [
      {
        id: 1.2,
        title: 'Нью-Йорк, США',
        order: 1,
        correctOrder: 2,
        image: getImageFromS3('images/split-lead-challenge/1.2.png')
      },
      {
        id: 1.1,
        title: 'Токіо, Японія',
        order: 2,
        correctOrder: 1,
        image: getImageFromS3('images/split-lead-challenge/1.1.png')
      },
      {
        id: 1.3,
        title: 'Берлін, Німеччина',
        order: 3,
        correctOrder: 3,
        image: getImageFromS3('images/split-lead-challenge/1.3.png')
      }
    ]
  },
  {
    id: 2,
    title: 'Де проживає найбільше мільйонерів?',
    answerList: [
      {
        id: 2.1,
        title: 'Нью-Йорк, США',
        order: 1,
        correctOrder: 1,
        image: getImageFromS3('images/split-lead-challenge/2.1.png')
      },
      {
        id: 2.2,
        title: 'Сан-Франциско, США',
        order: 2,
        correctOrder: 3,
        image: getImageFromS3('images/split-lead-challenge/2.2.png')
      },
      {
        id: 2.3,
        title: 'Токіо, Японія',
        order: 3,
        correctOrder: 2,
        image: getImageFromS3('images/split-lead-challenge/2.3.png')
      }
    ]
  },
  {
    id: 3,
    title: 'Де найдорожча нерухомість?',
    answerList: [
      {
        id: 3.1,
        title: 'Гонконг, Китай',
        order: 1,
        correctOrder: 2,
        image: getImageFromS3('images/split-lead-challenge/3.1.png')
      },
      {
        id: 3.2,
        title: 'Монако, Монако',
        order: 2,
        correctOrder: 1,
        image: getImageFromS3('images/split-lead-challenge/3.2.png')
      },
      {
        id: 3.3,
        title: 'Сінгапур, Сінгапур',
        order: 3,
        correctOrder: 3,
        image: getImageFromS3('images/split-lead-challenge/3.3.png')
      }
    ]
  },
  {
    id: 4,
    title: 'Яке місто найпопулярніше для шопінгу?',
    answerList: [
      {
        id: 4.1,
        title: 'Прага',
        order: 1,
        correctOrder: 3,
        image: getImageFromS3('images/split-lead-challenge/4.1.png')
      },
      {
        id: 4.2,
        title: 'Париж',
        order: 2,
        correctOrder: 1,
        image: getImageFromS3('images/split-lead-challenge/4.2.png')
      },
      {
        id: 4.3,
        title: 'Мілан',
        order: 3,
        correctOrder: 2,
        image: getImageFromS3('images/split-lead-challenge/4.3.png')
      }
    ]
  },
  {
    id: 5,
    title: 'Який регіон Європи найбагатший?',
    answerList: [
      {
        id: 5.1,
        title: 'Західний Лондон',
        order: 1,
        correctOrder: 1,
        image: getImageFromS3('images/split-lead-challenge/5.1.png')
      },
      {
        id: 5.2,
        title: 'Люксембург',
        order: 2,
        correctOrder: 2,
        image: getImageFromS3('images/split-lead-challenge/5.2.png')
      },
      {
        id: 5.3,
        title: 'Гамбург у Німеччині',
        order: 3,
        correctOrder: 3,
        image: getImageFromS3('images/split-lead-challenge/5.3.png')
      }
    ]
  }
];

const SplitLeadChallenge = ({
  backgroundImage,
  setScreenNumber,
  afterResultButtonScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isIntermediateScreen, setIsIntermediateScreen] = useState(false);
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    challengeList.map((q) =>
      [...q.answerList].sort((a, b) => a.order - b.order)
    )
  );
  const [isCorrect, setIsCorrect] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const currentAnswers = userAnswers[currentQuestionIndex];

  const currentQuestion = challengeList[currentQuestionIndex];

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const updatedAnswers = [...currentAnswers];
    const [removed] = updatedAnswers.splice(source.index, 1);
    updatedAnswers.splice(destination.index, 0, removed);

    const updatedUserAnswers = [...userAnswers];
    updatedUserAnswers[currentQuestionIndex] = updatedAnswers;

    setUserAnswers(updatedUserAnswers);
  };

  const isCorrectOrder = () => {
    return currentAnswers.every(
      (item, index) => item.correctOrder === index + 1
    );
  };

  const handleNextQuestion = () => {
    if (isCorrectOrder()) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
    setShowAnimation(true);

    setTimeout(() => {
      setShowAnimation(false);
      setIsCorrect(null);

      if (isCorrectOrder()) {
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex < challengeList.length) {
          setCurrentQuestionIndex(nextIndex);
          setChallengeStep(nextIndex);
        } else {
          setIsResultScreen(true); // Кінець тесту
        }
      }
    }, 2000);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      setIsIntermediateScreen(true);
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isIntermediateScreen && !isResultScreen && (
        <div className={styles.welcomeContainer}>
          <p className={styles.title}>Розстав першість</p>
          <p className={styles.description}>
            Дорогі мегаполіси задають темп та змушують постійно тримати хвилю.
            <br />
            <br />
            Перевір свою інтуїцію й знання та розстав міста за певною ознакою.
            <br />
            <br />
            На першому місці має стояти очільник рейтингу.
          </p>
          <button
            onClick={() => {
              setIsWelcomeScreen(false);
              setIsIntermediateScreen(true);
            }}
          >
            Поїхали
          </button>
        </div>
      )}
      {isIntermediateScreen && !isWelcomeScreen && !isResultScreen && (
        <div className={styles.intermediateContainer}>
          <p className={styles.title}>Порада</p>
          <div className={styles.description}>
            <img
              src={getImageFromS3('images/split-lead-challenge/advice.png')}
              alt="advice"
            />
            <p>Розтав у правильному порядку, переміщуючи відповіді</p>
          </div>
          <div
            className={styles.buttonContainer}
            style={{ marginTop: '160px' }}
          >
            <div
              className={styles.leftButton}
              onClick={() => {
                setIsWelcomeScreen(true);
                setIsIntermediateScreen(false);
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            <div
              className={styles.rightButton}
              onClick={() => {
                setIsIntermediateScreen(false);
              }}
            >
              <span>Далі</span>
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      )}
      {!isIntermediateScreen && !isWelcomeScreen && !isResultScreen && (
        <div className={styles.quizContainer}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div className={styles.challengeСontainer}>
            <h2>{currentQuestion.title}</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="answers">
                {(provided) => (
                  <div
                    className={styles.answersContainer}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {currentAnswers.map((answer, index) => (
                      <Draggable
                        key={answer.id}
                        draggableId={answer.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            key={answer.id}
                            className={classNames(
                              styles.answerItem,
                              { [styles.dragging]: snapshot.isDragging } // Додайте цей клас
                            )}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div>
                              <img src={answer.image} alt={answer.title} />
                              <p>{answer.title}</p>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="39"
                              height="15"
                              viewBox="0 0 39 15"
                              fill="none"
                            >
                              <rect
                                width="39"
                                height="3"
                                rx="1.5"
                                fill="#898A8E"
                              />
                              <rect
                                y="6"
                                width="39"
                                height="3"
                                rx="1.5"
                                fill="#898A8E"
                              />
                              <rect
                                y="12"
                                width="39"
                                height="3"
                                rx="1.5"
                                fill="#898A8E"
                              />
                            </svg>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {showAnimation && (
              <div
                className={classNames(
                  styles.animationOverlay,
                  isCorrect
                    ? styles.animationOverlayCorrect
                    : styles.animationOverlayIncorrect
                )}
              >
                <p>
                  {isCorrect
                    ? 'Все вірно!\n' + 'Нумо далі!'
                    : 'Не вірно!\n' + 'Cпробуй ще раз!'}
                </p>
              </div>
            )}
            <div className={styles.buttonContainer}>
              <div
                className={styles.leftButton}
                onClick={handlePreviousQuestion}
              >
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
                <span>Назад</span>
              </div>
              <div className={styles.rightButton} onClick={handleNextQuestion}>
                <span>Далі</span>
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isResultScreen && !isIntermediateScreen && !isWelcomeScreen && (
        <div className={styles.resultContainer}>
          <h2 className={styles.title}>Ну як тобі тест?</h2>
          <div className={styles.description}>
            {'Сподіваємось, що було цікаво.\n\n'}
            {'Здається, що тепер ти точно знаєш все і трохи більше.'}
          </div>
          <div className={styles.resultButtonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => setIsResultScreen(false)}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            <div
              className={styles.rightButton}
              onClick={afterResultButtonScreen}
            >
              <span>Далі</span>
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SplitLeadChallenge;
