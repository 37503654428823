import React from 'react';
import styles from './WarningFooter.module.scss';
import { useMemo } from 'react';

const WarningFooter = ({ screenNumber = 1, backgroundColor }) => {
  const showFooter = useMemo(() => {
    const n = screenNumber;
    return n === 1 || n === 2 || n === 3 || n === 4;
  }, [screenNumber]);

  if (!showFooter) return null;
  return (
    <div className={styles.footerContainer} style={{ backgroundColor }}>
      <span>Дана інформація про продукт</span>
      <span>надається відповідно до статті 15</span>
      <span>Закону України «Про захист прав</span>
      <span>споживачів».</span>
    </div>
  );
};

export default WarningFooter;
