import React, { useCallback, useContext } from 'react';
import styles from './index.module.scss';
import global from '../index.module.scss';
import HeadContainer from '../head-container';
import classNames from 'classnames';
import { DataLayerEventContext } from '../../contexts/dataLayerEventContext';
import useTelegramLink from '../../hooks/useTelegramLink';
import { getImageFromS3 } from '../../imageService';

const FinalScreen = ({
  setScreenNumber,
  setScreenNumberToReview,
  withReviewButton = false
}) => {
  const { telegramLink } = useTelegramLink();
  const { sendEvent } = useContext(DataLayerEventContext);
  const onTelegramClick = useCallback(() => {
    window.open(telegramLink, '_blank');
    sendEvent('telegram');
  }, [sendEvent, telegramLink]);

  const onViberClick = useCallback(() => {
    window.open('viber://pa?chatURI=support_pm_bot', '_blank');
    sendEvent('viber');
  }, [sendEvent]);

  const onLeaveFeedbackClick = useCallback(() => {
    if (setScreenNumberToReview) {
      setScreenNumberToReview();
    } else {
      setScreenNumber(12);
    }
    sendEvent('review');
  }, [sendEvent, setScreenNumber, setScreenNumberToReview]);
  return (
    <div
      className={styles.finalScreenContainer}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/light-blue-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} isDarkMode />
      <div className={styles.contentContainer}>
        <div>
          <div className={styles.titleContainer}>
            <p>Дякую,</p>
            <p>що обираєш продукцію Філіп Морріс Україна.</p>
          </div>
          <div className={styles.textContainer}>
            <p>{`Ти завжди можеш бути на звʼязку зі своїм брендом.\n\n Долучайся до нас у месенджерах`}</p>
          </div>
          <div className={styles.linkContainer}>
            <img
              src={getImageFromS3('images/telegram.png')}
              alt="telegram-icon"
              onClick={onTelegramClick}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.buttonContainer,
            !withReviewButton && styles.buttonContainerWithMargin
          )}
        >
          <button
            className={global.menuButton}
            onClick={() => setScreenNumber(1)}
          >
            Повернутися до меню
          </button>
          {withReviewButton && (
            <button
              className={global.reviewButton}
              style={{ marginTop: 10 }}
              onClick={onLeaveFeedbackClick}
            >
              Залишити відгук
            </button>
          )}
          <div className={styles.infoContainer}>
            <div>
              <p>Контент оновлюється кожного місяця</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;
