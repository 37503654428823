import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const SuccessScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container} style={{ backgroundImage }}>
      <p className={styles.title}>Готово!</p>
      <p className={styles.description}>
        Ти успішно відписався від отримання інформації про продукцію Філіп
        Морріс Україна засобами дистанційного зв’язку згідно ч. 2 ст. 15 Закону
        України "Про захист прав споживачів".
      </p>
      <img src={getImageFromS3('images/unsubscribe/img.png')} alt="image" />
    </div>
  );
};

export default SuccessScreen;
