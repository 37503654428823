import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';

const TwoImageSelectChallenge = ({
  setScreenNumber,
  setAfterResultScreen,
  challengeList,
  resultList,
  backgroundImage
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [challengeResult, setChallengeResult] = useState([]);
  const [isResultView, setIsResultView] = useState(false);
  const [result, setResult] = useState(null);

  const handleSelect = (answer) => {
    const isExistAnswer = challengeResult.find(
      (item) => item.questionId === selectQuestion.id
    );
    if (isExistAnswer) {
      const updatedResultList = challengeResult.filter(
        (item) => item.questionId !== selectQuestion.id
      );
      setChallengeResult([
        ...updatedResultList,
        { questionId: selectQuestion.id, answerId: answer.id }
      ]);
    } else {
      setChallengeResult([
        ...challengeResult,
        { questionId: selectQuestion.id, answerId: answer.id }
      ]);
    }
  };

  const handleViewResult = () => {
    const firstAnswerList = challengeResult.filter(
      (item) => item.answerId === '1'
    );
    const secondAnswerList = challengeResult.filter(
      (item) => item.answerId === '2'
    );

    if (firstAnswerList.length > secondAnswerList.length) {
      setResult(resultList[0]);
    } else {
      setResult(resultList[1]);
    }

    setIsResultView(true);
  };

  return (
    <div
      className={styles.imageSelectContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {!isResultView && !result && (
        <>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          {selectQuestion && (
            <div className={styles.questionItem}>
              <span className={styles.titleQuestion}>
                {selectQuestion.title}
              </span>
              <div className={styles.imageList}>
                {selectQuestion.imageList.map((item) => {
                  const answer = challengeResult?.find(
                    (item) => item.questionId === selectQuestion.id
                  );

                  return (
                    <div
                      id={item.id}
                      onClick={() => handleSelect(item)}
                      className={classNames(
                        styles.imageContainer,
                        answer?.answerId === item.id &&
                          styles.selectedImageContainer
                      )}
                    >
                      <img
                        src={item.url}
                        alt={item.id}
                        className={styles.imageItem}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className={styles.buttonContainer}>
            {challengeStep !== 0 && (
              <button
                className={global.reviewButton}
                onClick={() => {
                  setSelectQuestion(challengeList[challengeStep - 1]);
                  setChallengeStep(challengeStep - 1);
                }}
              >
                Назад
              </button>
            )}
            <button
              className={global.menuButton}
              onClick={() => {
                if (challengeList.length > challengeStep + 1) {
                  setSelectQuestion(challengeList[challengeStep + 1]);
                  setChallengeStep(challengeStep + 1);
                } else {
                  handleViewResult();
                }
              }}
              disabled={
                !challengeResult.find(
                  (item) => item.questionId === selectQuestion.id
                )
              }
            >
              Далі
            </button>
          </div>
        </>
      )}
      {isResultView && result && (
        <div className={styles.resultContainer}>
          <div>
            <h3 className={styles.resultHeading}>результат</h3>
          </div>
          <div>
            <p className={styles.resultTitle}>{result.title}</p>
          </div>
          <div>
            <p className={styles.resultDescription}>{result.description}</p>
            <button
              className={global.reviewButton}
              onClick={setAfterResultScreen}
            >
              Далі
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TwoImageSelectChallenge;
