import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';
import { getImageFromS3 } from '../../../imageService';

const lineList = [
  {
    image: getImageFromS3('images/packs/philip-morris/novel-purple.png'),
    title: 'Philip Morris Novel Purple',
    pageNumber: 14
  },
  {
    image: getImageFromS3('images/packs/chesterfield/retuned.png'),
    title: 'Chesterfield Retuned',
    pageNumber: 17
  },
  {
    image: getImageFromS3('images/packs/lm/loft-purple.png'),
    title: 'L&M Loft Purple',
    pageNumber: 15
  },
  {
    image: getImageFromS3('images/packs/lm/loft-green.png'),
    title: 'L&M Loft Green',
    pageNumber: 16
  }
];

const WelcomeScreen = ({ backgroundImage, setScreenNumber }) => {
  return (
    <div className={styles.wrapperContainer}>
      <div
        className={styles.container}
        style={{ backgroundImage: backgroundImage }}
      >
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.infoContainer}>
          <span>Сигарети </span>
          <span>Bond Street Premium Mix</span>
          <p className={styles.description}>
            скоро зникнуть з полиць магазинів згідно з новим
            <span onClick={() => setScreenNumber(14)}> законодавством.</span>
          </p>
          <img
            src={getImageFromS3('images/packs/bond/premium-mix.png')}
            alt="pack"
          />
          <img
            src={getImageFromS3('images/lm-double-splash/red-ribbons.png')}
            alt="ribbons"
            className={styles.ribbons}
          />
          <p className={styles.bottomTitle} onClick={() => setScreenNumber(2)}>
            Bond Street Premium Mix
          </p>
        </div>
        <div className={styles.lineContainer}>
          <p>Тому звертаємо увагу</p>
          <p>на інші сигарети на заміну, які доступні до продажу:</p>
          <div className={styles.lineListContainer}>
            {lineList.map((item) => {
              return (
                <div
                  className={styles.lineItem}
                  onClick={() => setScreenNumber(item.pageNumber)}
                >
                  <div className={styles.imageContainer}>
                    <img src={item.image} alt={item.image} />
                  </div>
                  <p>{item.title}</p>
                  <img
                    className={styles.arrow}
                    src="/images/lm-double-splash/rigth-circle-arrow.svg"
                    alt="arrow-right"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <button
          className={styles.nextButton}
          onClick={() => setScreenNumber(1)}
        >
          Далі
        </button>
      </div>
      <WarningFooter />
    </div>
  );
};

export default WelcomeScreen;
