import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const AppreciationScreen = ({ backgroundImage }) => {
  const backButtonLink = sessionStorage.getItem('unsubscribeRedirect') || null;

  return (
    <div className={styles.container} style={{ backgroundImage }}>
      <p className={styles.title}>Дякуємо, що залишаєшся з нами!</p>
      <img src={getImageFromS3('images/unsubscribe/img.png')} alt="image" />
      <a href={`/${backButtonLink}`}>Повернутися на головну</a>
    </div>
  );
};

export default AppreciationScreen;
