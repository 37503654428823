import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../components/lm-d-s/head-container';
import { getImageFromS3 } from '../../imageService';

const FactoryScreen = ({
  setScreenNumber,
  backButtonClick,
  nextButtonClick
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.infoContainer}
        style={{
          backgroundImage: `url("${getImageFromS3(
            'images/lm-double-splash/violet-background.png'
          )}")`
        }}
      >
        <HeadContainer setScreenNumber={setScreenNumber} />
        <h1>Нова фабрика на Львівщині</h1>
        <h2 style={{ marginTop: 24 }}>Потужність фабрики</h2>
        <p>
          Фабрика облаштована сучасним обладнанням для виробництва сиграет.
          Планується мати 5 виробничих ліній під різні формати продуктів і
          брендів. Перша лінія була запущена у травні і ще чотири будуть введені
          в експлуатацію до кінця року, що виведе виробничу потужність фабрики
          на 10 млрд штук сигарет на рік і повністю покриє потреби компанії у
          забезпеченні українського ринку сигаретною продукцією.
        </p>
        <img
          src={getImageFromS3('images/common/factory/factory.png')}
          alt="factory"
        />
        <h2 style={{ marginTop: 40 }}>Робочі місця</h2>
        <p>
          На новій фабриці буде створено 250 робочих місць. Зараз на
          підприємстві вже працює близько 100 працівників, які були релоковані з
          харківської фабрики Філіп Морріс, ще 150 працівників будуть релоковані
          до кінця 2024 року.
        </p>
        <img
          src={getImageFromS3('images/common/factory/working-places.png')}
          alt="factory"
        />
        <h2 style={{ marginTop: 40 }}>
          Коли з'являться на ринку сигарети вироблені на новій фабриці?
        </h2>
        <p>
          Зовсім скоро почнуть з'являтись пачки на ринку України вироблені на
          новій тютюновій фабриці. Споживачі це зможуть помітити на маркуванні
          пачок, де буде вказано місце виробництва продукту.
        </p>
        <h2 style={{ marginTop: 40 }}>
          Які сигарети будуть виготовлятись на новій фабриці?
        </h2>
        <p>
          Компанія планує перейти повністю на всі продукти з виробництвом в
          Україні на власній фабриці, проте це буде потребувати додаткового часу
          та зусиль, тому перехід буде відбуватись поступово.
        </p>
        <img
          src={getImageFromS3('images/common/factory/technologies.png')}
          alt="factory"
        />
        <h2 style={{ marginTop: 40 }}>
          Чи зміняться продуктові характеристики?
        </h2>
        <p>
          Продуктові характеристики сигарет не зміняться з переходом на нову
          фабрику, оскільки високі стандарти виробництва сигарет на фабриках
          Філіп Морріс однакові.
        </p>
        <div style={{ margin: '40px 0 15px 0' }}>
          <button className={styles.menuButton} onClick={backButtonClick}>
            Повернутись до меню
          </button>
          <button
            className={styles.reviewButton}
            style={{ marginTop: 14 }}
            onClick={nextButtonClick}
          >
            Далі
          </button>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <span>Дана інформація про продукт надається</span>
        <span>відповідно до статті 15 Закону України</span>
        <span>«Про захист прав споживачів».</span>
      </div>
    </div>
  );
};

export default FactoryScreen;
