const names = [
  {
    '23UA-MLB-T': 'Marlboro Touch',
    '23UA-MLB-F-T': 'Marlboro Fine Touch',
    '23UA-BND-P-6': 'Bond Premium Blue',
    '23UA-MPM-4': 'Philip Morris Novel Silver',
    '23UA-LM-4': 'L&M Loft Sea Blue',
    '23UA-LM-6': 'L&M Loft Blue',
    '23UA-BND-P-4': 'Bond Premium Silver',
    '23UA-MPM-6': 'Philip Morris Novel Blue',
    '23UA-Ches-6': 'Chesterfield Compact Blue',
    '23UA-Ches-4': 'Chesterfield Compact Silver',
    '23UA-PRL-S-6': 'Parliament SoHo Compact Blue',
    '23UA-PRL-S-4': 'Parliament SoHo Compact Silver',
    '23UA-PRL-V': 'Parliament SoHo Vibe',
    '24UA-Ches-Re': 'Chesterfield Retuned',
    '24UA-LM-L-Green': 'L&M Loft Green',
    '24UA-LM-L-Pr': 'L&M Loft Purple',
    '24UA-MPM-PR': 'Philip Morris Novel Purple',
    '24UA-Ches-SP': 'Chesterfield Special Purple',
    '24UA-Ches-SR': 'Chesterfield Special Red',
    '24UA-LM-SP': 'L&M Special Purple',
    '24UA-LM-SR': 'L&M Special Red',
    '24UA-BND-B': 'Bond Street Blue',
    '24UA-BND-R': 'Bond Street Red',
    '24UA-LM-B': 'L&M Blue Label',
    '24UA-LM-R': 'L&M Red Label',
    '24UA-MLB-G': 'Marlboro Gold',
    '24UA-MLB-R': 'Marlboro Red',
    '24UA-MLB-S': 'Marlboro Silver',
    '24UA-Ches-O': 'Chesterfield Original',
    '24UA-Ches-B': 'Chesterfield Blue',
    '24UA-MLB-T-LE': 'Marlboro Touch Red',
    '24UA-MLB-F-T-LE': 'Marlboro Touch Gold',
    '24UA-Ches-O-NB': 'Chesterfield Compact Orange',
    '24UA-LM-Spl': 'L&M Loft Splash',
    '23UA-LM-D-S': 'L&M Double Splash',
    '23UA-MPM-N-M': 'Philip Morris Novel Mix',
    '23UA-MPM-N-M-S': 'Philip Morris Novel Mix Summer ',
    '23UA-BND-P-M': 'Bond Premium Mix',
    '23UA-LM-L-M': 'LM Loft Mix',
    '24UA-KV': 'Віжуал Сільпо',
    '24UA-PRL-N': 'Parliament Night',
    '24UA-PRL-A': 'Parliament Aqua',
    '24UA-PRL-S': 'Parliament Silver',
    '24UA-PRL-P': 'Parliament Platinum',
    '24UA-PRL-A-SS': 'Parliament Aqua 100 SSL',
    '24UA-PRL-S-SS': 'Parliament Silver 100 SSL',
    '24UA-PRL-Prpl': 'Parliament Purple',
    '24UA-Ches-R': 'Chesterfield Red',
    '24UA-MLB-Prpl': 'Marlboro Purple',
    '24UA-PRL-N-LPE': 'Parliament Night LPE',
    '24UA-PRL-A-LPE': 'Parliament Aqua LPE',
    '24UA-PRL-S-LPE': 'Parliament Silver LPE',
    '24UA-PRL-P-LPE': 'Parliament Platinum LPE',
    '25UA-MLB-Ttn': 'Marlboro Gold Titanium',
    '24UA-KV3': 'Visual',
    '24UA-KV4': 'Visual',
    '25UA-KV1': 'Visual',
    '25UA-KV2': 'Visual',
    '25UA-KV3': 'Visual',
    '25UA-KV4': 'Visual',
    '25UA-KV5': 'Visual',
    '25UA-KV6': 'Visual',
    '25UA-Ches-Sum': 'Chesterfield Summer',
    '25UA-Ches-Prpl': 'Chesterfield Purple',
    '25UA-LM-L-R': 'L&M Loft Red',
    '25UA-LM-FC-R': 'L&M First Cut Red',
    '25UA-LM-FC-B': 'L&M First Cut Blue',
    '25UA-LM-LFC-B': 'L&M Loft First Cut Blue',
    '25UA-LM-LFC-S': 'L&M Loft First Cut Sea Blue',
    '25UA-PRL-Sh-A': 'Parliament Soho Aqua Blue KS STD',
    '25UA-PRL-Sh-S': 'Parliament Soho Silver Blue KS STD',
    '25UA-Ches-O-100': 'Chesterfield Original 100 STD',
    '25UA-Ches-B-100': 'Chesterfield Blue 100 STD',
    '25UA-PRL-Sh-A-SSL': 'Parliament Soho NYC Aqua Super Slims',
    '25UA-PRL-Sh-S-SSL': 'Parliament Soho NYC Silver Super Slims',
    '25UA-MLB-R-100': 'Marlboro Red 100',
    '25UA-MLB-G-100': 'Marlboro Gold 100'
  }
];

export default function useProductName() {
  const projectPath = sessionStorage.getItem('isQrCode');

  return names[0][projectPath];
}
