import React from 'react';
import styles from './index.module.scss';

const WelcomeScreen = ({
  setScreenNumber,
  backgroundImage,
  approveButtonClick,
  cancelButtonClick
}) => {
  return (
    <div className={styles.container} style={{ backgroundImage }}>
      <p className={styles.title}>Нам прикро, що ти залишаєш нас :(</p>
      <div className={styles.subTitleContainer}>
        <p className={styles.subTitle}>Ти дійсно бажаєш відписатись?</p>
        <div className={styles.buttonContainer}>
          <button onClick={approveButtonClick}>Так</button>
          <button onClick={cancelButtonClick}>Ні</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
