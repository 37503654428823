import axios from 'axios';

const get = (url) => requestApi('get', url);
const post = (url, requestConfig) => requestApi('post', url, requestConfig);
const put = (url, requestConfig) => requestApi('put', url, requestConfig);
const deleteApi = (url, requestConfig) =>
  requestApi('delete', url, requestConfig);

const requestApi = async (method, url, requestConfig) => {
  try {
    let config = {
      method: method,
      url: requestConfig?.data?.otherUrl
        ? requestConfig?.data?.otherUrl
        : `${process.env.REACT_APP_PROD_BACKEND_URL}/${url}`
    };

    const updatedConfig = requestConfig;

    delete updatedConfig?.data?.otherUrl;

    config = { ...config, ...updatedConfig };

    return await request(config);
  } catch (e) {
    console.log(`requestApi: url: ${url}; requestConfig: ${JSON.stringify(
      requestConfig
    )},
        method: ${method}; error: ${e}; response data: ${JSON.stringify(
          e.response?.data
        )}`);

    throw e;
  }
};

const request = (requestConfig) => axios.request(requestConfig);

export default {
  get,
  put,
  post,
  deleteApi
};
