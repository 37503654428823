import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../../common/head-container';
import ImageSelectChallenge from '../image-select-challenge';
import classNames from 'classnames';

export default function SelectSeasonChallenge({
  setScreenNumber,
  backgroundImage,
  setNextScreenNumber,
  type = 'default',
  style = null
}) {
  const [result, setResult] = useState(false);
  const [data, setData] = useState({
    challengeList: [],
    results: []
  });

  useEffect(() => {
    (async () => {
      const { challengeList, results } = await import(`./utils/${type}.js`);
      const [_challengeList, _results] = await Promise.all([
        challengeList,
        results
      ]);
      setData({
        challengeList: _challengeList,
        results: _results
      });
    })();
  }, [type]);

  const handleSubmitQuiz = (result) => {
    const items = result.map((it) => it.answerId);
    const most = items
      .sort(
        (a, b) =>
          items.filter((v) => v === a).length -
          items.filter((v) => v === b).length
      )
      .pop();
    setResult(data?.results[most - 1] || []);
  };

  return (
    <div
      className={classNames(styles.quizContainer, style && styles[style])}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {!result && (
        <ImageSelectChallenge
          challengeList={data?.challengeList}
          onSubmit={(result) => handleSubmitQuiz(result)}
          style={style}
        />
      )}
      {result && (
        <div className={styles.resultContainer}>
          <p className={styles.resultTitle}>Результат:</p>
          <div className={styles.resultsInfo}>
            {(style === 'soho' || style === 'harmonyInSelfKnowledge') && (
              <img
                src={result.url}
                alt={result.id}
                className={styles.resultImage}
              />
            )}
            <div className={styles.resultInfoWr}>
              <p
                className={styles.resultTitle}
                style={{
                  background: result.titleBackground,
                  fontSize: 28,
                  padding: style !== 'soho' ? '16px 0' : null
                }}
              >
                {result.title}
              </p>

              {style !== 'soho' && style !== 'harmonyInSelfKnowledge' && (
                <img
                  src={result.url}
                  alt={result.id}
                  className={styles.resultImage}
                />
              )}

              <p className={styles.resultDescription}>{result.description}</p>
            </div>
          </div>
          <button
            className={global.reviewButton}
            style={{ marginTop: 10 }}
            onClick={setNextScreenNumber}
          >
            Далі
          </button>
        </div>
      )}
    </div>
  );
}
