import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../../imageService';

const ThirdScreen = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Приклади нових медичних попереджень</p>
      <p className={styles.content}>
        Усі ілюстрації медичних попереджень опубліковані на відповідному ресурсі
        <a
          className={styles.link}
          href={'https://zakon.rada.gov.ua/laws/show/z0902-23#Text'}
        >
          (Про затвердження Груп наборів ко... | від 18.04.2023 № 747
          (rada.gov.ua))
        </a>
        , але ми зібрали декілька зображень, що можна буде побачити на упаковках
        у 2024 році для прикладу нижче.
      </p>
      <img
        src={getImageFromS3('images/ghws/third.png')}
        alt="first"
        className={styles.image}
      />
    </div>
  );
};

export default ThirdScreen;
