import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';
import { getImageFromS3 } from '../../../imageService';

const lineList = [
  {
    title: 'L&M Loft Green',
    pack: getImageFromS3('images/packs/lm/loft-green.png'),
    tobaccoFirst: 'Вміст смол, мг - 5',
    tobaccoSecond: 'Вміст нікотину, мг - 0.5',
    filterIcon: getImageFromS3('images/parliament/filter.png'),
    background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
    isDarkButton: true,
    newMixtureText: 'Особлива тютюнова суміш',
    isNewMixture: true,
    isKV: true,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: <span>Вугільна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'L&M Loft Purple',
    pack: getImageFromS3('images/packs/lm/loft-purple.png'),
    tobaccoFirst: 'Вміст смол, мг - 5',
    tobaccoSecond: 'Вміст нікотину, мг - 0.5',
    filterIcon: getImageFromS3('images/parliament/filter.png'),
    background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
    isDarkButton: true,
    isNewMixture: true,
    isKV: true,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: <span>Вугільна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'Chesterfield Retuned',
    pack: getImageFromS3('images/packs/chesterfield/retuned.png'),
    background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
    tobaccoFirst: 'Вміст смол, мг - 5',
    tobaccoSecond: 'Вміст нікотину, мг - 0.5',
    filterIcon: getImageFromS3('images/parliament/filter.png'),
    isDarkButton: true,
    isNewMixture: true,
    newMixtureText: 'Особлива тютюнова суміш',
    isKV: true,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: <span>Вугільна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'Philip Morris Novel Purple',
    pack: getImageFromS3('images/packs/philip-morris/novel-purple.png'),
    background: `url("${getImageFromS3('images/lm-l-green/background.png')}")`,
    tobaccoFirst: 'Вміст смол, мг - 5',
    tobaccoSecond: 'Вміст нікотину, мг - 0.5',
    filterIcon: getImageFromS3('images/parliament/filter.png'),
    isDarkButton: true,
    isNewMixture: true,
    isKV: true,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: <span>Вугільна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  }
];

const WelcomeScreen = ({
  backgroundImage,
  setScreenNumber,
  handleNextButton,
  handlePackSelect
}) => {
  return (
    <div className={styles.wrapperContainer}>
      <div
        className={styles.container}
        style={{ backgroundImage: backgroundImage }}
      >
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.headContainer}>
          <p className={styles.headTitle}>Сигарети з капсулами</p>
          <p className={styles.subTitle}>
            скоро зникнуть з полиць магазинів відповідно до нового
            законодавства*
          </p>
        </div>
        <div className={styles.lineContainer}>
          <img
            src={getImageFromS3('images/kv/welcome.png')}
            alt="welcome"
            className={styles.image}
          />
          <p>Нові продукти:</p>
          <div className={styles.lineListContainer}>
            {lineList.map((item) => {
              return (
                <div
                  className={styles.lineItem}
                  onClick={() => handlePackSelect(item)}
                >
                  <div className={styles.imageContainer}>
                    <img src={item.pack} alt={item.pack} />
                  </div>
                  <p>{item.title}</p>
                  <img
                    src="/images/lm-double-splash/rigth-circle-arrow.svg"
                    className={styles.arrow}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.tasteInfo}>
            <p className={styles.title}>
              Чи містять нові продукти якийсь смак?
            </p>
            <p className={styles.content}>
              Продукти не мають характерного смаку/аромату, окрім тютюну.
              Містять функціональні добавки, які підкреслюють смак продуктів.
            </p>
          </div>
          <div className={styles.info}>
            * Про внесення змін до деяких законів України щодо охорони здоров’я
            населення від шкідливого впливу тютюну
          </div>
        </div>
        <button className={styles.nextButton} onClick={handleNextButton}>
          Далі
        </button>
      </div>
      <WarningFooter />
    </div>
  );
};

export default WelcomeScreen;
