import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const tobaccoCircleLink = getImageFromS3('images/sms-prl/tobacco-circle.png');
const shopLink = {
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
  cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/'
};

const HomeScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ backgroundImage }}>
        <p className={styles.title}>Привіт!</p>
        <p className={styles.subTitle}>
          Ти залишав запит на отримання інформації про продукт засобами
          дистанційного зв’язку згідно ч. 2 ст. 15 Закону України "Про захист
          прав споживачів", тому ділимося інформацією про оновлення в лінійці
          Parliament.
        </p>
        <img
          src={getImageFromS3('images/sms-prl/logo.png')}
          alt="logo"
          className={styles.logo}
        />
        <p className={styles.logoSubTitle}>
          Лімітована серія* <br /> в унікальному дизайні
        </p>
        <img
          src={getImageFromS3('images/sms-prl/packs.png')}
          alt="packs"
          className={styles.packs}
        />
        <p className={styles.packsSubtitle}>у “Кінг сайз” форматі</p>
        <p className={styles.packsDescription}>
          *Регулярний продукт (у звичному дизайні) також доступний у точках
          продажу. Продукт має відмінний дизайн, але незмінні продуктові
          характеристики.
        </p>
        <div className={styles.generalFilter}>
          <p>
            RECESSED 2.0 <br />
            Filter
          </p>
          <img
            src={getImageFromS3('images/sms-prl/general-filter.png')}
            alt="packs"
          />
        </div>
        <div className={styles.filterList}>
          <p className={styles.title}>Фільтр має:</p>
          <div className={styles.filterItem}>
            <img
              src={getImageFromS3('images/sms-prl/filter1.png')}
              alt="filter1"
            />
            <p>
              ацетатну <br /> частину
            </p>
          </div>
          <div className={styles.filterItem}>
            <img
              src={getImageFromS3('images/sms-prl/filter2.png')}
              alt="filter2"
            />
            <p>
              вугільну <br /> частину
            </p>
          </div>
          <div className={styles.filterItem}>
            <img
              src={getImageFromS3('images/sms-prl/filter3.png')}
              alt="filter3"
            />
            <p>ущільнену повітряну камеру з ацетатною стінкою</p>
          </div>
        </div>
        <div className={styles.tobacco}>
          <p className={styles.title}>Бленд з трьох сортів тютюну</p>
          <div className={styles.tobaccoContent}>
            <img
              src={getImageFromS3('images/sms-prl/tobacco.png')}
              alt="tobacco"
            />
            <div className={styles.typeList}>
              <div className={styles.listItem}>
                <img src={tobaccoCircleLink} alt="circle" />
                <p>Берлі</p>
              </div>
              <div className={styles.listItem}>
                <img src={tobaccoCircleLink} alt="circle" />
                <p>Вірджинія</p>
              </div>
              <div className={styles.listItem}>
                <img src={tobaccoCircleLink} alt="circle" />
                <p>Орієнтал</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buyContainer}>
          <p className={styles.title}>Де купити?</p>
          <p className={styles.description}>
            Актуальний асортимент можна знайти у роздрібних точках продажу в
            твоєму місті або на офіційних онлайн сервісах, зокрема:
          </p>
          <div className={styles.buttonContainer}>
            <button onClick={() => window.open(shopLink.rozetka, '_blank')}>
              <p>Rozetka</p>
            </button>
            <button onClick={() => window.open(shopLink.cigPoshta, '_blank')}>
              <p>Cig-poshta</p>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
      <div className={styles.unsubscribe}>
        Ти отримав лист, оскільки залишав запит на отримання інформації про
        продукцію компаній Philip Morris в Україні. <br />
        <a href="/unsubscribe?returnLink=24-sms-prl">
          Відписатися від SMS тут.
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;
