import React, { useContext, useEffect, useState } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/lm-d-s/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import WelcomeScreen from '../components/hstss/welcome-screen';
import ResultScreen from '../components/hstss/result-screen';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import { getImageFromS3 } from '../imageService';

const HSTSS2 = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('hstss2');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case 1: {
        return (
          <WelcomeScreen
            setScreenNumber={setScreenNumber}
            handleNextScreen={() => setScreenNumber(2)}
          />
        );
      }
      case 2: {
        return (
          <CommerceVideoScreen
            videoSrc={getImageFromS3('videos/hstss.mp4')}
            setScreenNumber={cb}
            nextScreenNumber={3}
            changeScreenImmediately
            controls
          />
        );
      }
      case 3: {
        return (
          <ResultScreen
            handleNextScreen={() => setScreenNumber(1)}
            qrCodeSrc={getImageFromS3('images/hstss/tg-qr-2.png')}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="hstss1-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default HSTSS2;
