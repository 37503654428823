import React, { useCallback } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import classNames from 'classnames';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import { useContext } from 'react';
import useTelegramLink from '../../../hooks/useTelegramLink';
import { getImageFromS3 } from '../../../imageService';

const FinalScreen = ({
  setScreenNumber,
  setScreenNumberToReview,
  handleBackButton = () => setScreenNumber(1),
  withReviewButton = false
}) => {
  const { telegramLink } = useTelegramLink();
  const { sendEvent } = useContext(DataLayerEventContext);
  const onTelegramClick = useCallback(() => {
    window.open(telegramLink, '_blank');
    sendEvent('telegram');
  }, [sendEvent, telegramLink]);

  const onLeaveFeedbackClick = useCallback(() => {
    if (setScreenNumberToReview) {
      setScreenNumberToReview();
    } else {
      setScreenNumber(12);
    }
    sendEvent('review');
  }, [sendEvent, setScreenNumber, setScreenNumberToReview]);

  return (
    <div
      className={styles.finalScreenContainer}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/violet-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div>
          <div className={styles.titleContainer}>
            <p>Дякую,</p>
            <p>що обираєш продукцію Філіп Морріс Україна.</p>
          </div>
          <div className={styles.textContainer}>
            <p>
              Долучайся до нас у телеграмі, щоб бути на зв’язку зі своїм
              брендом.
            </p>
          </div>
          <div className={styles.linkContainer}>
            <img
              src={getImageFromS3('images/lm-double-splash/telegram.png')}
              alt="telegram-icon"
              onClick={onTelegramClick}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.buttonContainer,
          !withReviewButton && styles.buttonContainerWithMargin
        )}
      >
        <button className={styles.menuButton} onClick={handleBackButton}>
          Повернутися до меню
        </button>
        {withReviewButton && (
          <button
            className={styles.reviewButton}
            style={{ marginTop: 10 }}
            onClick={onLeaveFeedbackClick}
          >
            Залишити відгук
          </button>
        )}
        <div className={styles.infoContainer}>
          <p>Контент регулярно оновлюється</p>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;
