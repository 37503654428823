import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const FinalScreen = ({ setScreenNumber, withReviewButton = false }) => {
  const onTakeSurvey = () => {
    window.open(
      'https://www.scanpack.com/ua-kv?utm_campaign=24UA-KVreg',
      'blank'
    );
  };

  return (
    <div
      className={styles.finalScreenContainer}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/violet-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div>
          <div className={styles.titleContainer}>
            <p>Хочеш отримувати новини від Філіп Морріс Україна?</p>
            <p>
              Проходь невелике опитування та отримуй{' '}
              <b>20 грн на мобільний за витрачений час.</b>
            </p>
          </div>
        </div>
        <div className={styles.telegramContainer}>
          <img
            src={getImageFromS3(getImageFromS3('/images/kv3/final-screen.png'))}
            alt="icon"
          />
        </div>
        <div
          className={classNames(
            styles.buttonContainer,
            !withReviewButton && styles.buttonContainerWithMargin
          )}
        >
          <button className={styles.menuButton} onClick={onTakeSurvey}>
            Пройти опитування
          </button>
          <div className={styles.infoContainer}>
            <p>Контент регулярно оновлюється</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;
