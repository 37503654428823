import React, { Fragment } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import WarningFooter from '../../../common/warning-footer/WarningFooter';
import { getImageFromS3 } from '../../../imageService';

const infoList = [
  {
    title: '1994',
    subtitle: 'PMI вийшла на український ринок',
    text: 'Philip Morris International вийшла на український ринок у 1994 році та представлена ПрАТ «Філіп Морріс Україна» і ТОВ «Філіп Морріс Сейлз енд Дистриб’юшн». Головний український офіс Філіп Морріс знаходиться за адресою м. Київ, вул. Спаська, 30.'
  },
  {
    title: '$739 млн',
    subtitle: 'Загальний обсяг інвестицій',
    text:
      'Пріоритетними напрямками інвестування компанії стали бездимні продукти для нагрівання тютюну, інноваційний виробничий центр біля Харкова та нова фабрика на Львівщині.\n' +
      'У 2024 рокі Філіп Морріс запустив останню версію пристрою для нагрівання тютюну IQOS ILUMA, в яку лише цього року компанія вкладе 11 млн доларів. Ці кошти спрямують у збільшення кількості магазинів, розширення сервісної інфраструктури та створення нових робочих місць.'
  },
  {
    title: 'Нова фабрика',
    subtitle: 'в Україні',
    text:
      'Компанія має інноваційний виробничий центр біля Харкова в селищі Докучаєвське. Вона виготовляла низку міжнародних брендів як для українського, так і для закордонних ринків.  На виробництві було задіяно більше тисячі осіб. Проте з початком повномасштобної війни компанія була змушена зупинити виробництво з міркувань безпеки працівників.\n\n' +
      'У 2024 році Філіп Морріс відкриває нову фабрику, в яку інвестує $30 млн. Відкривши власне виробництво в Україні, компанія зможе краще зберігати контроль над якістю та швидкістю виробництва і логістичними ланцюгами. Також локальне виробництво вигідне не лише для Філіп Морріс, а і для України загалом. Перш за все – це робочі місця, адже на фабриці працюватиме 250 осіб. А щойно підприємство запрацює наповну, одне робоче місце на фабриці забезпечить додаткові десять у суміжних галузях.'
  },
  {
    title: '27 млд грн',
    subtitle: 'Податків сплачено до державного та місцевих за 2023 рік',
    text: 'За період роботи в Україні ПрАТ «Філіп Морріс Україна» і ТОВ «Філіп Морріс Сейлз енд Дистриб’юшн» сплатили близько 14,4 млрд доларів податків до державного та місцевих бюджетів. З них у 2023 році — 27 млрд грн.'
  },
  {
    title: 'Серед ТОП-3',
    subtitle: 'найкращих роботодавців України 2024 року',
    text: 'Нещодавно Forbes оприлюднив рейтинг найкращих роботодавців за 2024 рік та відзначив Philip Morris у трійці лідерів разом з двома компаніями з ІТ сектору. Причому, ще у 2022 році компанія була на 34 позиції, у 2023 на 5, а цього року зайняла 3 місце. Наша компанія може пишатись найвищим рівнем eNPS – 85% та високою часткою співробітників/ць, які працюють більше 10 років. А також розміром заробітної плати, яка у Philip Morris значно перевищує середні показники. Журі рейтингу високо оцінили і наш бренд роботодавця, присвоївши йому 18 балів з 20 можливих, та соціальний пакет, який отримав оцінку 10 із 10.'
  },
  {
    title: 'Соціальні',
    subtitle: 'проєкти',
    text:
      'Компанія Філіп Морріс виділила сумарно 400 млн грн на благодійні та гуманітарні проєкти від початку великої війни. Компанія сфокусувалась на трьох основних напрямах: допомозі медичним закладам, на відновленні пошкодженої інфраструктури та на реалізації соціальних проєктів. Ключовими регіонами для гуманітарної допомоги компанії є Харківська, Київська та Львівська області.\n\n' +
      'У 2023 році ми продовжили підтримувати українців та закупили обладнання на $550 тис. для лікарень Львівської, Вінницької, Сумської, Харківської, Миколаївської та Дніпропетровської областей.\n\n' +
      'Не залишилась без підтримки і рідна Харківщина, де знаходиться фабрика. Компанія виділила 6 млн грн на будівництво укриття у Харківській області та закупила генератори для потреб деокупованих громад.\n\n' +
      'Філіп Морріс виділила 1,5 млн грн для «Жінкомобіля» — спеціального діагностичного авто для медичних оглядів жінок на деокупованих територіях Харківської, Херсонської та Донецької областей.'
  }
];

const InfoScreen = ({ setScreenNumber, setReviewScreenNumber }) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.infoContainer}
        style={{
          backgroundImage: `url("${getImageFromS3(
            'images/lm-double-splash/violet-background.png'
          )}")`
        }}
      >
        <HeadContainer setScreenNumber={setScreenNumber} />
        <span className={styles.title}>Філіп Морріс Україна</span>
        {infoList.map((item, index) => (
          <Disclosure key={index} id={index.toString()}>
            {({ open }) => (
              <>
                <Disclosure.Button className={styles.panelButton}>
                  <div>
                    <p>{item.title}</p>
                    <p>{item.subtitle}</p>
                  </div>
                  {open ? (
                    <img
                      src="images/lm-double-splash/white-minus.svg"
                      alt="open-tab-icon"
                    />
                  ) : (
                    <img
                      src="images/lm-double-splash/white-plus.svg"
                      alt="open-tab-icon"
                    />
                  )}
                </Disclosure.Button>
                <Transition
                  as={Fragment}
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className={styles.panelContent}>
                    {item.text}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
        <div style={{ margin: '20px 0 15px 0' }}>
          <button
            className={styles.menuButton}
            onClick={() => setScreenNumber(1)}
          >
            Повернутись до меню
          </button>
          <button
            className={styles.reviewButton}
            style={{ marginTop: 14 }}
            onClick={setReviewScreenNumber}
          >
            Далі
          </button>
        </div>
      </div>
      <WarningFooter backgroundColor="#491585" />
    </div>
  );
};

export default InfoScreen;
