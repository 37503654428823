import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../common/head-container';
import global from '../index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const ChallengeScreen = ({ setScreenNumber }) => {
  return (
    <div
      className={styles.challengeScreen}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/menu-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.headContainer}>
        <img
          src={getImageFromS3('images/lm-double-splash/blue-ellipse.png')}
          alt="blue-ellipse"
          className={styles.ellipse}
        />
        <p>Прагнеш нових відкриттів?</p>
      </div>
      <div className={styles.bottomContainer}>
        Кожен обирає щось своє. Хтось прагне нових відкриттів, а комусь не
        хочеться різноманіття.
      </div>
      <button
        className={global.menuButton}
        style={{ marginTop: 10 }}
        onClick={() => setScreenNumber(6)}
      >
        Пройди тест
      </button>
    </div>
  );
};

export default ChallengeScreen;
