import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const challengeList = [
  {
    id: 1,
    title: 'Який твій улюблений спосіб відновлення енергії після важкого дня?',
    answerList: [
      { id: 1.1, title: 'А. Спорт чи медитація' },
      { id: 1.2, title: 'В. Прогулянка на свіжому повітрі' },
      { id: 1.3, title: 'С. Перегляд фільму та смачна вечеря' }
    ]
  },
  {
    id: 2,
    title: 'З яким краєвидом тобі найприємніше прокидатись?',
    answerList: [
      {
        id: 2.1,
        title: 'А. Гірським',
        image: getImageFromS3('images/exclusive-screensaver-challenge/2.1.png')
      },
      {
        id: 2.2,
        title: 'В. Морським',
        image: getImageFromS3('images/exclusive-screensaver-challenge/2.2.png')
      },
      {
        id: 2.3,
        title: 'С. Міським',
        image: getImageFromS3('images/exclusive-screensaver-challenge/2.3.png')
      }
    ]
  },
  {
    id: 3,
    title: 'Твій ідеальний вихідний?',
    answerList: [
      { id: 3.1, title: 'А. Присвячу час родині' },
      { id: 3.2, title: 'В. Поїду кудись з друзями' },
      { id: 3.3, title: 'С. Проведу день на самоті' }
    ]
  },
  {
    id: 4,
    title: 'Твій улюблений час доби?',
    answerList: [
      {
        id: 4.1,
        title: 'А. Ранок',
        image: getImageFromS3('images/exclusive-screensaver-challenge/4.1.png')
      },
      {
        id: 4.2,
        title: 'В. День',
        image: getImageFromS3('images/exclusive-screensaver-challenge/4.2.png')
      },
      {
        id: 4.3,
        title: 'С. Вечір',
        image: getImageFromS3('images/exclusive-screensaver-challenge/4.3.png')
      }
    ]
  },
  {
    id: 5,
    title: 'В яку погоду відчуватимеш найбільше натхнення?',
    answerList: [
      { id: 5.1, title: 'А. Дощову та пасмурну' },
      { id: 5.2, title: 'В. Сонячну й теплу' },
      { id: 5.3, title: 'С. Сніжну та морозну' }
    ]
  }
];

// Do not use getImageFromS3 for result images
const resultList = [
  {
    id: 1,
    image: '/images/exclusive-screensaver-challenge/PRL-1.png'
  },
  {
    id: 2,
    image: '/images/exclusive-screensaver-challenge/PRL-2.png'
  },
  {
    id: 3,
    image: '/images/exclusive-screensaver-challenge/PRL-3.png'
  },
  {
    id: 4,
    image: '/images/exclusive-screensaver-challenge/PRL-4.png'
  },
  {
    id: 5,
    image: '/images/exclusive-screensaver-challenge/PRL-5.png'
  },
  {
    id: 6,
    image: '/images/exclusive-screensaver-challenge/PRL-6.png'
  },
  {
    id: 7,
    image: '/images/exclusive-screensaver-challenge/PRL-7.png'
  },
  {
    id: 8,
    image: '/images/exclusive-screensaver-challenge/PRL-8.png'
  },
  {
    id: 9,
    image: 'images/exclusive-screensaver-challenge/PRL-9.png'
  }
];

const ExclusiveScreensaverChallenge = ({
  setScreenNumber,
  backgroundImage,
  afterResultButtonScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [correctAnswerValue, setCorrectAnswerValue] = useState({});
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultScreen, setIsResultScreen] = useState(false);
  const [answers, setAnswers] = useState([]);

  const onAnswerClick = useCallback(
    (value) => {
      setAnswers((prev) => ({ ...prev, [challengeStep]: value }));
    },
    [answers, challengeStep]
  );

  const onFinishClick = () => {
    const answerCounts = {};
    for (const key in answers) {
      const answerNumber = parseInt(
        answers[key].answerId.toString().split('.')[1]
      );
      answerCounts[answerNumber] = (answerCounts[answerNumber] || 0) + 1;
    }

    let mostFrequentAnswer = null;
    let maxCount = 0;
    for (const answer in answerCounts) {
      if (answerCounts[answer] > maxCount) {
        maxCount = answerCounts[answer];
        mostFrequentAnswer = parseInt(answer);
      }
    }

    setCorrectAnswerValue(
      resultList.find((item) => item?.id === mostFrequentAnswer)
    );
    setIsResultScreen(true);
  };

  const handleAfterResult = async () => {
    afterResultButtonScreen();
    setIsResultScreen(false);
    setIsWelcomeScreen(true);
    setChallengeStep(0);
    setCorrectAnswerValue({});
    setAnswers([]);
    setSelectQuestion(challengeList[0]);
  };

  const handleDownload = () => {
    const answer2 = answers[1]?.answerId;
    const answer5 = answers[4]?.answerId;

    let resultId = null;

    if (answer2 === 2.1 && answer5 === 5.1) resultId = 1;
    else if (answer2 === 2.1 && answer5 === 5.2) resultId = 3;
    else if (answer2 === 2.1 && answer5 === 5.3) resultId = 4;
    else if (answer2 === 2.2 && answer5 === 5.1) resultId = 5;
    else if (answer2 === 2.2 && answer5 === 5.2) resultId = 6;
    else if (answer2 === 2.2 && answer5 === 5.3) resultId = 7;
    else if (answer2 === 2.3 && answer5 === 5.1) resultId = 8;
    else if (answer2 === 2.3 && answer5 === 5.2) resultId = 9;
    else if (answer2 === 2.3 && answer5 === 5.3) resultId = 2;

    const result = resultList.find((item) => item.id === resultId);

    const imageUrl = result.image;
    const fileName = `PRL-${resultId}.png`;

    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '20px 30px 0' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultScreen && (
        <div className={styles.welcomeContainer}>
          <p className={styles.title}>Твоя ексклюзивна заставка</p>
          <p className={styles.description}>
            Ти як ніхто знаєш, що диявол в деталях. Аксесуари, інтер'єр чи
            робочий стіл можуть багато розповісти оточенню про нас.
            <br />
            <br /> Пройди тест та отримай ексклюзивну заставку на телефон, що
            найкраще відображатиме твій статус та смак.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>Розпочати</button>
        </div>
      )}
      {!isWelcomeScreen && !isResultScreen && (
        <div className={styles.challengeContainer}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div className={styles.questionContainer}>
            <p className={styles.title}>{selectQuestion?.title}</p>
            <div className={styles.answerList}>
              {selectQuestion &&
                selectQuestion?.answerList?.map((item, index) => (
                  <div
                    key={index.toString()}
                    className={classNames(
                      styles.answerItem,
                      item?.id === answers[challengeStep]?.answerId &&
                        styles.selectedAnswerItem
                    )}
                    style={
                      item?.image
                        ? { backgroundImage: `url(${item?.image})` }
                        : {}
                    }
                    onClick={() =>
                      onAnswerClick({ answerId: item?.id, answer: item?.title })
                    }
                  >
                    <p>{item.title}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => {
                if (challengeStep > 0) {
                  setChallengeStep(challengeStep - 1);
                  setSelectQuestion(challengeList[challengeStep - 1]);
                } else {
                  setIsWelcomeScreen(true);
                  setSelectQuestion(challengeList[0]);
                  setChallengeStep(0);
                  setIsResultScreen(false);
                  setAnswers([]);
                }
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            {answers[challengeStep]?.answerId && (
              <div
                className={styles.rightButton}
                onClick={() => {
                  if (challengeStep + 1 >= challengeList?.length) {
                    onFinishClick();
                    return;
                  }
                  setChallengeStep(challengeStep + 1);
                  setSelectQuestion(challengeList[challengeStep + 1]);
                }}
              >
                <span>Далі</span>
                <img
                  src="/images/luxury-expert-challenge/arrow.svg"
                  alt="arrow"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {isResultScreen && !!correctAnswerValue && (
        <div className={styles.resultContainer}>
          <p className={styles.title}>Дякуємо, що пройшов тест до кінця!</p>
          <p className={styles.description}>
            Завантажуй заставку на телефон, створену на основі твоїх відповідей,
            за посиланням.
          </p>
          <button onClick={handleDownload}>Завантажити</button>
          <div className={styles.buttonContainer}>
            <div
              className={styles.leftButton}
              onClick={() => {
                setChallengeStep(challengeList?.length - 1);
                setIsResultScreen(false);
                setCorrectAnswerValue({});
              }}
            >
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
              <span>Назад</span>
            </div>
            <div className={styles.rightButton} onClick={handleAfterResult}>
              <span>Далі</span>
              <img
                src="/images/luxury-expert-challenge/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExclusiveScreensaverChallenge;
