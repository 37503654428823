import React, { useMemo, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../components/lm-d-s/head-container';
import WarningFooter from '../../components/lm-d-s/warning-footer/WarningFooter';
import CharacteristicScreen from '../../components/lm-d-s/characteristic-screen';
import { brandList } from './const';
import classNames from 'classnames';

const BrandLineScreen = ({
  setScreenNumber,
  backButtonClick,
  nextButtonClick,
  brandId
}) => {
  const brand = useMemo(
    () => brandList.find((item) => item.brandId === brandId),
    [brandId]
  );
  const [selectedPack, setSelectedPack] = useState(null);

  return brand && !selectedPack ? (
    <div
      className={styles.wrapperContainer}
      style={{ backgroundImage: brand.background }}
    >
      <div className={styles.container}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.contentContainer}>
          <p className={styles.title}>{brand.brandTitle}</p>
          <div className={styles.linesContainer}>
            {brand.lines.map((line) => {
              return (
                <div className={styles.lineItem}>
                  <p className={styles.lineTitle}>{line.lineTitle}</p>
                  <div className={styles.packList}>
                    {line.packsLine.map((packItem) => {
                      return (
                        <div
                          className={classNames(
                            styles.packItem,
                            packItem.isDarkButton && styles.darkPackItem
                          )}
                          onClick={() => setSelectedPack(packItem)}
                        >
                          <div className={styles.imageContainer}>
                            <img src={packItem.pack} alt={packItem.pack} />
                          </div>
                          <p>{packItem.title}</p>
                          <img
                            className={styles.arrow}
                            src="/images/all-brands/right-arrow.svg"
                            alt="arrow-right"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.menuButton} onClick={backButtonClick}>
            Повернутися до вибору бренду
          </button>
          <button
            className={styles.reviewButton}
            style={{ marginTop: 10 }}
            onClick={nextButtonClick}
          >
            Далі
          </button>
        </div>
      </div>
      <WarningFooter />
    </div>
  ) : selectedPack && brand ? (
    <CharacteristicScreen
      brandId={brand.brandId}
      isNewMixture={selectedPack.isNewMixture}
      newMixtureText={selectedPack?.newMixtureText ?? undefined}
      title={selectedPack.title}
      setScreenNumber={setScreenNumber}
      filterCharacteristicList={selectedPack.filterCharacteristicList}
      infoList={selectedPack.characteristicInfoList}
      tobaccoFirst={selectedPack.tobaccoFirst}
      tobaccoIcon={selectedPack?.tobaccoIcon}
      paperIcon={selectedPack?.paperIcon}
      paperText={selectedPack?.paperText}
      characteristicListTitle={selectedPack?.characteristicListTitle}
      tobaccoSecond={selectedPack.tobaccoSecond}
      packImage={selectedPack.pack}
      handleBackButton={() => setSelectedPack(null)}
      handleNextButton={nextButtonClick}
      backgroundImage={selectedPack.background ?? brand.background}
      filterIcon={selectedPack.filterIcon}
      isMarlboroSpecial={selectedPack.isMarlboroSpecial}
      nextButtonTitle="Далі"
      fullRangeButtonClick={() => {
        backButtonClick();
        setSelectedPack(null);
      }}
    />
  ) : null;
};

export default BrandLineScreen;
