import React, { useState } from 'react';
import styles from './index.module.scss';
import InputMask from 'react-input-mask';
import apiService from '../../../utils/apiService';

const PhoneApproveScreen = ({ backgroundImage, handleNextButtonClick }) => {
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = async () => {
    const unsubscriptionSource = sessionStorage.getItem(
      'unsubscription_source'
    );
    const regex = /^380\d{9}$/;
    const updatedPhone = phone.replace(/\D/g, '').replace(/^380/, '380');

    if (regex.test(updatedPhone)) {
      try {
        await apiService.post('unsubscription', {
          data: {
            phone: updatedPhone,
            unsubscription_source: unsubscriptionSource || 'Parliament_Limited'
          }
        });
        handleNextButtonClick();
      } catch (e) {
        setError('Щось пішло не так, спробуйте ще раз!');
      }
    } else {
      setError('Невірний формат номера');
    }
  };

  return (
    <div className={styles.container} style={{ backgroundImage }}>
      <p className={styles.title}>Дякуємо, що був(-ла) з нами!</p>
      <div className={styles.inputContainer}>
        <p className={styles.subTitle}>
          Введи свій номер телефону, щоб відписатися:
        </p>
        <InputMask
          mask="+380 (99) 999-99-99"
          maskChar="_"
          alwaysShowMask={true}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onFocus={() => setError('')}
        >
          {(inputProps) => <input {...inputProps} type="tel" />}
        </InputMask>
        {error && <p className={styles.error}>Невірний формат номера</p>}
      </div>
      <button onClick={handleSubmit}>Далі</button>
    </div>
  );
};

export default PhoneApproveScreen;
