import React, { useCallback, useContext } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../common/head-container';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../imageService';

const StartScreen = ({
  setScreenNumber,
  setNextScreenNumber,
  backgroundImage,
  eventName = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setNextScreenNumber();
    sendEvent(eventName || 'start_challenge');
  }, [setScreenNumber, sendEvent]);
  return (
    <div
      className={styles.startScreenContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <img
            src={getImageFromS3('images/common/blue-ellipse.png')}
            alt="blue-ellipse"
            className={styles.ellipse}
          />
          <p>приготуйся до інтерактивної пригоди</p>
        </div>
        <span>
          У цій грі тобі треба робити вибір, <br />
          який вплине на фінал історії. <br />
          Подивись, куди заведуть твої <br />
          рішення!
        </span>
        <button
          className={classNames(styles.button, global.menuButton)}
          onClick={onButtonClickHandler}
        >
          Почати
        </button>
      </div>
    </div>
  );
};

export default StartScreen;
