import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../../common/head-container';
import classNames from 'classnames';
import { getImageFromS3 } from '../../../../imageService';

const questions = [
  {
    question: 'Який з цих напоїв популярніший у світі?',
    options: [
      {
        text: 'Чай',
        isCorrect: true,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/1.1.png')
      },
      {
        text: 'Кава',
        isCorrect: false,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/1.2.png')
      }
    ],
    correctTitle: 'Правильно!',
    correctFeedback: 'Чай — найпопулярніший напій у світі після води.',
    incorrectTitle: 'Не вгадав!',
    incorrectFeedback:
      'Насправді чай є найпопулярнішим напоєм у світі після води.'
  },
  {
    question: "Яка з цих пам'яток вища?",
    options: [
      {
        text: 'Ейфелева вежа',
        isCorrect: true,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/2.1.png')
      },
      {
        text: 'Статуя Свободи',
        isCorrect: false,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/2.2.png')
      }
    ],
    correctTitle: 'Так!',
    correctFeedback:
      'Висота Ейфелевої вежі — 324 м. А американський монумент заввишки 93 м.',
    incorrectTitle: 'А от і ні!',
    incorrectFeedback:
      'Висота Ейфелевої вежі — 324 м. А американський монумент заввишки 93 м.'
  },
  {
    question: 'Яке море є глибшим?',
    options: [
      {
        text: 'Чорне море',
        isCorrect: false,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/3.1.png')
      },
      {
        text: 'Червоне море',
        isCorrect: true,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/3.2.png')
      }
    ],
    correctTitle: 'Так!',
    correctFeedback:
      'Червоне море трішки глибше за Чорне. Його найбільша глибина — 3040 м, а Чорного — 2245 м.',
    incorrectTitle: 'Неправильно!',
    incorrectFeedback:
      'Червоне море трішки глибше за Чорне. Його найбільша глибина — 3040 м, а Чорного — 2245 м.'
  },
  {
    question: 'Що з цього вище?',
    options: [
      {
        text: 'Везувій',
        isCorrect: false,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/4.1.png')
      },
      {
        text: 'Говерла',
        isCorrect: true,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/4.2.png')
      }
    ],
    correctTitle: 'Правильна відповідь!',
    correctFeedback:
      'Говерла трішечки вища за Везувій, її висота – 2061 м, а Везувія — 1281 м.',
    incorrectTitle: 'Неправильна відповідь!',
    incorrectFeedback:
      'Говерла трішечки вища за Везувій, її висота – 2061 м, а Везувія — 1281 м.'
  },
  {
    question: 'Який з цих каменів більш рідкісний?',
    options: [
      {
        text: 'Рубін',
        isCorrect: true,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/5.1.png')
      },
      {
        text: 'Діамант',
        isCorrect: false,
        image: getImageFromS3('/images/special-capsules/quiz-challenge/5.2.png')
      }
    ],
    correctTitle: 'Правильно!',
    correctFeedback:
      'Високоякісні рубіни зустрічаються значно рідше, ніж діаманти.',
    incorrectTitle: 'Хибна відповідь!',
    incorrectFeedback:
      'Насправді високоякісні рубіни зустрічаються значно рідше, ніж діаманти.'
  }
];

const QuizGame = ({ backgroundImage, setScreenNumber, handleNextScreen }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isWelcome, setIsWelcome] = useState(true);
  const [isResult, setIsResult] = useState(false);

  const handleOptionClick = (isCorrect) => {
    if (!showFeedback) {
      setIsCorrect(isCorrect);
      setShowFeedback(true);
    }
  };

  const handleNextQuestion = () => {
    setShowFeedback(false);
    setCurrentQuestion(currentQuestion + 1);

    if (questions?.length <= currentQuestion + 1) setIsResult(true);
  };

  const handleBackQuestion = () => {
    setShowFeedback(false);
    setCurrentQuestion(currentQuestion - 1);
  };

  return (
    <div
      className={styles.content}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {isWelcome && (
        <div className={styles.welcomeContainer}>
          <div className={styles.circleContainer}>
            <img
              src={getImageFromS3(
                'images/special-capsules/your-top-challenge/orange-circle.png'
              )}
              alt="circle"
            />
            <p>У тебе хороша інтуїція? Перевірмо її з нашою новою грою!</p>
          </div>
          <div className={styles.description}>
            Ми покажемо тобі два варіанти, а твоя задача — вгадати, що з цього
            №1.
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.menuButton}
              onClick={() => setIsWelcome(false)}
            >
              Далі
            </button>
          </div>
        </div>
      )}
      {isResult && !isWelcome && (
        <div className={styles.resultContainer}>
          <div className={styles.circleContainer}>
            <img
              src={getImageFromS3(
                'images/special-capsules/your-top-challenge/orange-circle.png'
              )}
              alt="circle"
            />
            <img
              src={getImageFromS3(
                'images/special-capsules/quiz-challenge/result.png'
              )}
              alt="result"
            />
          </div>
          <p className={styles.title}>Ну як, вдалося все відгадати?</p>
          <p className={styles.description}>
            Бути кращим — це стиль життя. Саме для тих, хто цінує першість, й
            існує наш бренд.
          </p>
          <div className={styles.buttonContainer}>
            <button className={styles.menuButton} onClick={handleNextScreen}>
              Далі
            </button>
          </div>
        </div>
      )}
      {!isWelcome && !isResult && (
        <div className={styles.quizContainer}>
          {currentQuestion < questions.length && (
            <>
              <div className={styles.question}>
                {questions[currentQuestion].question}
              </div>
              <div className={styles.options}>
                {questions[currentQuestion].options.map((option, index) => (
                  <div
                    key={index.toString()}
                    className={styles.option}
                    onClick={() => handleOptionClick(option.isCorrect)}
                  >
                    <p>{option?.text}</p>
                    <img src={option.image} alt={option?.text} />
                    {showFeedback && option?.isCorrect === isCorrect && (
                      <div className={styles.answerContainer}>
                        <p
                          className={classNames(
                            styles.title,
                            isCorrect && styles.correctTitle
                          )}
                        >
                          {isCorrect
                            ? questions[currentQuestion]?.correctTitle
                            : questions[currentQuestion]?.incorrectTitle}
                        </p>
                        <p>
                          {isCorrect
                            ? questions[currentQuestion].correctFeedback
                            : questions[currentQuestion].incorrectFeedback}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          <div className={styles.buttonContainer}>
            {/*{currentQuestion > 0 && (*/}
            {/*  <button*/}
            {/*    className={styles.backButton}*/}
            {/*    onClick={handleBackQuestion}*/}
            {/*  >*/}
            {/*    Назад*/}
            {/*  </button>*/}
            {/*)}*/}
            {showFeedback && (
              <button
                className={styles.menuButton}
                onClick={handleNextQuestion}
              >
                Далі
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizGame;
