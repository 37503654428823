import { getImageFromS3 } from '../../../../imageService';

export const challengeList = [
  {
    id: '1',
    title: 'Який елемент декору впишеться у твою оселю?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/special-capsules/aroma-challenge/1.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/special-capsules/aroma-challenge/1.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/special-capsules/aroma-challenge/1.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/special-capsules/aroma-challenge/1.4.png')
      }
    ]
  },
  {
    id: '2',
    title: 'Яке захоплення тебе найбільше надихає?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/special-capsules/aroma-challenge/2.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/special-capsules/aroma-challenge/2.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/special-capsules/aroma-challenge/2.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/special-capsules/aroma-challenge/2.4.png')
      }
    ]
  },
  {
    id: '3',
    title: 'Яке зображення найкраще передає твій настрій?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/special-capsules/aroma-challenge/3.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/special-capsules/aroma-challenge/3.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/special-capsules/aroma-challenge/3.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/special-capsules/aroma-challenge/3.4.png')
      }
    ]
  },
  {
    id: '4',
    title: 'Яку заставку для телефону обереш?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/special-capsules/aroma-challenge/4.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/special-capsules/aroma-challenge/4.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/special-capsules/aroma-challenge/4.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/special-capsules/aroma-challenge/4.4.png')
      }
    ]
  },
  {
    id: '5',
    title: 'Який аксесуар підкреслить твій стиль?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3('images/special-capsules/aroma-challenge/5.1.png')
      },
      {
        id: '2',
        url: getImageFromS3('images/special-capsules/aroma-challenge/5.2.png')
      },
      {
        id: '3',
        url: getImageFromS3('images/special-capsules/aroma-challenge/5.3.png')
      },
      {
        id: '4',
        url: getImageFromS3('images/special-capsules/aroma-challenge/5.4.png')
      }
    ]
  }
];

export const resultList = [
  {
    id: '1',
    image: getImageFromS3(
      'images/special-capsules/aroma-challenge/result-1.png'
    ),
    title: 'Лаванда',
    description:
      'Цей аромат підкреслить твій спокійний характер. Як і лаванда, ти маєш заспокійливий ефект на своє оточення. Тому з тобою приємно, легко та комфортно.'
  },
  {
    id: '2',
    image: getImageFromS3(
      'images/special-capsules/aroma-challenge/result-2.png'
    ),
    title: 'Шоколад',
    description:
      'Ти приємна людина, яка підіймає настрій усім знайомим. А ще поруч з тобою так і хочеться посміхатися. Тож аромат шоколаду тобі дуже пасуватиме.'
  },
  {
    id: '3',
    image: getImageFromS3(
      'images/special-capsules/aroma-challenge/result-3.png'
    ),
    title: 'Лайм',
    description:
      'Аромат твого яскравого характеру — лаймовий. Ти маєш повно енергії, яку спрямовуєш на саморозвиток. А ще на втілення блискучих ідей, що регулярно з’являються у твоїй голові.'
  },
  {
    id: '4',
    image: getImageFromS3(
      'images/special-capsules/aroma-challenge/result-4.png'
    ),
    title: 'Біла лілія',
    description:
      'Як і білій лілії, тобі притаманна загадковість. Через неї людям інколи важко розгадати, що в тебе на душі. Цю загадковість гармонійно доповнює вишуканість і хороший смак.'
  }
];
