import React from 'react';
import styles from './index.module.scss';
import global from '../index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const ErrorScreen = ({ setScreenNumber }) => {
  return (
    <div
      className={styles.errorScreen}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/light-blue-background.png'
        )}")`
      }}
    >
      <p>404</p>
      <p>Упс...</p>
      <p>Такої сторінки не існує.</p>
      <button className={global.menuButton} onClick={() => setScreenNumber(1)}>
        Повернутися на головну
      </button>
    </div>
  );
};

export default ErrorScreen;
