import React from 'react';
import styles from './WarningFooter.module.scss';
import { useMemo } from 'react';

const WarningFooter = ({ screenNumber }) => {
  const showFooter = useMemo(() => {
    const n = screenNumber || 0;
    return n === 0 || n === 2 || n === 3 || n === 4;
  }, [screenNumber]);

  if (!showFooter) return null;
  return (
    <div className={styles.footerContainer}>
      <span>Куріння викликає серйозні</span>
      <span>захворювання та залежність</span>
      <span>Дана інформація про продукт надається</span>
      <span>відповідно до статті 15 Закону України</span>
      <span>«Про захист прав споживачів»</span>
    </div>
  );
};

export default WarningFooter;
