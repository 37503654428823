import React from 'react';
import styles from './index.module.scss';
import { getImageFromS3 } from '../../../imageService';

const shopLink = {
  rozetka:
    'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce,chesterfield,philip-morris;soderganie-smoli=5-mg/',
  cigPoshta:
    'https://cig-poshta.in.ua/product-tag/absoliutno-nova-tiutiunova-sumish/?utm_source=sms-low-menthol-w4'
};

const HomeScreen = ({ backgroundImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ backgroundImage }}>
        <div className={styles.title}>
          <p>Привіт!</p>
          <p>
            Повідомляємо, що новітні тютюнові вироби з капсулою вже зникли з
            полиць. Проте, у продажу все ще доступні сигарети з особливою
            тютюновою сумішшю.
          </p>
        </div>
        <img
          src={getImageFromS3('images/sms-nonflv/head-image.png')}
          alt="head-image"
          className={styles.headImage}
        />
        <div className={styles.packsContainer}>
          <p className={styles.title}>
            У продажу все ще доступні сигарети з особливою тютюновою сумішшю в
            такому ж форматі.
          </p>
          <img
            src={getImageFromS3('images/sms-nonflv/arrow.png')}
            alt="arrow-image"
            className={styles.arrowBackgroundImage}
          />
          <img
            src={getImageFromS3('images/sms-nonflv/canceled-pack.png')}
            alt="arrow-image"
            className={styles.canceledImage}
          />
          <img
            src={getImageFromS3('images/sms-nonflv/still-pack.png')}
            alt="arrow-image"
            className={styles.stillImage}
          />
          <p className={styles.bottomSubtitle}>Компактний формат</p>
        </div>
        <div className={styles.filterContainer}>
          <img
            className={styles.icon}
            src={getImageFromS3('images/sms-nonflv/filter.png')}
            alt="filter-icon"
          />
          <div className={styles.characteristicListTitle}>
            <p>Оригінальний Recessed™ фільтр</p>
            <p>Фільтр складається з трьох частин:</p>
          </div>
          <p>Ацетатна частина</p>
          <p>Вугільна частина</p>
          <p>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </p>
        </div>
        <div className={styles.tobaccoContainer}>
          <img
            className={styles.tobaccoImage}
            src={getImageFromS3('images/sms-nonflv/tobacco.png')}
            alt="filter-icon"
          />
          <p className={styles.tobaccoText}>Особлива тютюнова суміш</p>
        </div>
        <div className={styles.buyContainer}>
          <p className={styles.title}>Де купити?</p>
          <p className={styles.description}>
            Актуальний асортимент можна знайти у роздрібних точках продажу в
            твоєму місті або на офіційних онлайн сервісах, зокрема:
          </p>
          <div className={styles.buttonContainer}>
            <button onClick={() => window.open(shopLink.rozetka, '_blank')}>
              <p>Rozetka</p>
            </button>
            <button onClick={() => window.open(shopLink.cigPoshta, '_blank')}>
              <p>Cig-poshta</p>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Куріння викликає серйозні захворювання та залежність Дана інформація про
        продукт надається відповідно до статті 15 Закону України «Про захист
        прав споживачів»
      </div>
      <div className={styles.unsubscribe}>
        Ти отримав(-ла) лист, оскільки залишав(-ла) запит на отримання
        інформації про продукцію компаній Philip Morris в Україні. <br />
        <a href="/unsubscribe?returnLink=25-sms-nonflv-test">
          Відписатися від SMS тут.
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;
