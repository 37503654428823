import React, { useCallback, useContext, useEffect, useState } from 'react';
import FirstScreen from '../components/ghws/pages/first-screen';
import SecondScreen from '../components/ghws/pages/second-screen';
import ThirdScreen from '../components/ghws/pages/third-screen';
import FourthScreen from '../components/ghws/pages/fourth-screen';
import ResultScreen from '../components/ghws/pages/result-screen';
import swiperStyles from '../common/challenge-component/swipe-challenge/index.module.scss';
import styles from '../components/lm-d-s/index.module.scss';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import classNames from 'classnames';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import { getImageFromS3 } from '../imageService';

const screenList = [
  { id: 0, content: <FirstScreen /> },
  { id: 1, content: <SecondScreen /> },
  { id: 2, content: <ThirdScreen /> },
  { id: 3, content: <FourthScreen /> },
  { id: 4, content: <ResultScreen /> }
];

const GHWS = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    init('GHWS');
  }, [init]);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const moveToNextSlide = useCallback(() => {
    if (swiper) {
      swiper.slideTo(currentIndex + 1);
    }
  }, [swiper, currentIndex]);

  return (
    <div className={styles.pageContainer} id="ghws-screen-container">
      <div
        className={styles.contentContainer}
        style={{
          backgroundImage: `url("${getImageFromS3(
            'images/lm-double-splash/violet-background.png'
          )}")`,
          backgroundSize: 'cover'
        }}
      >
        <div className={styles.ghwsStepToolbar}>
          {screenList.map((item, index) => (
            <div
              className={classNames(
                styles.stepItem,
                item?.id === currentIndex && styles.activeStepItem
              )}
              key={index.toString()}
            />
          ))}
        </div>
        <div>
          <Swiper
            slidesPerView={1}
            spaceBetween={25}
            modules={[Navigation]}
            className={swiperStyles.ghwsSwipe}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            <div>
              {screenList?.map((item) => {
                return <SwiperSlide key={item?.id}>{item.content}</SwiperSlide>;
              })}
            </div>
          </Swiper>
        </div>
        {currentIndex < 4 && (
          <img
            src={getImageFromS3('images/ghws/arrow.png')}
            alt="first"
            className={styles.ghwsArrowImage}
            onClick={moveToNextSlide}
          />
        )}
      </div>
    </div>
  );
};

export default GHWS;
