import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../head-container';
import { getImageFromS3 } from '../../../../imageService';
import InputMask from 'react-input-mask';
import apiService from '../../../../utils/apiService';
import useProductName from '../../../../hooks/useProductName';

const PhoneInputComponent = ({
  setScreenNumber,
  handleBackButtonClick,
  data,
  handleSuccessOtpSend
}) => {
  const productName = useProductName();
  const projectPath = sessionStorage.getItem('isQrCode');
  const [isError, setIsError] = useState(false);
  const [phone, setPhone] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => window.scrollTo(0, 0), [data]);

  const handleSubmit = async () => {
    const regex = /^380\d{9}$/;
    const updatedPhone = phone.replace(/\D/g, '').replace(/^380/, '380');

    if (regex.test('380' + updatedPhone) && data.files && isChecked) {
      const formData = new FormData();

      data.files.forEach((file) => {
        formData.append('files', file);
      });

      formData.append('phone', '380' + updatedPhone);
      formData.append('link', 'https://yourjourney.com.ua/' + projectPath);
      formData.append('sku_name', productName);
      Object.entries(data).forEach(([key, value]) => {
        if (key !== 'files') {
          formData.append(key, value);
        }
      });

      try {
        const {
          data: { id, status }
        } = await apiService.post('feedback', {
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        localStorage.setItem('rw_id', id);
        handleSuccessOtpSend(status);
      } catch (e) {
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <div
      className={styles.phoneNumberContainer}
      style={{
        backgroundImage: `url("${getImageFromS3(
          'images/lm-double-splash/violet-background.png'
        )}")`
      }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.content}>
        <p className={styles.title}>Введи, будь ласка, свій номер телефону</p>
        <div className={styles.phoneInputContainer}>
          <div className={styles.labelContainer}>
            <p>Код</p>
            <p>Номер телефону*</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.codeContainer}>+380</div>
            <InputMask
              mask="(99) 999-99-99"
              maskChar="_"
              alwaysShowMask={true}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onFocus={() => setIsError(false)}
              className={styles.input}
            >
              {(inputProps) => <input {...inputProps} type="tel" />}
            </InputMask>
          </div>
        </div>
        <p className={styles.subtitle}>
          Отримати поповнення за відгук про продукт можна один раз на місяць
        </p>
        <p className={styles.description}>
          *поповнення мобільного телефону можливе для усіх операторів України
          окрім контрактних номерів
        </p>
        <div className={styles.privacyPolicy}>
          <input
            type="checkbox"
            id="privacy"
            name="privacy"
            onClick={() => {
              setIsChecked(!isChecked);
              setIsError(false);
            }}
            checked={isChecked}
          />
          <label htmlFor="privacy">
            Я погоджуюсь з{' '}
            <a
              href="https://yourjourney.com.ua/personal-data-policy"
              target="_blank"
            >
              положенням про конфіденційність та захист персональних даних
            </a>
          </label>
        </div>
        {isError && <p className={styles.error}>Заповни всі поля</p>}
        <button
          className={styles.menuButton}
          style={{ marginTop: 11 }}
          onClick={handleSubmit}
        >
          Надіслати номер
        </button>
        <button
          className={styles.reviewButton}
          style={{ marginTop: 14 }}
          onClick={handleBackButtonClick}
        >
          Повернутися назад
        </button>
      </div>
    </div>
  );
};

export default PhoneInputComponent;
